import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { BillingParkingSession } from '../../../shared/models/parkingSession';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { BillingResolve } from '../../../shared/models/routeTyping';
import { breakPoints } from '../../../core/utils/common.data';
import { sortArray } from '../../../shared/utils/common.utils';

@Component({
  selector: 'app-billing-park-sessions',
  templateUrl: './billing-parking-sessions.component.html',
  styleUrl: './billing-parking-sessions.component.scss',
})
export class BillingParkingSessionsComponent implements OnInit, AfterViewInit {
  _destroyRef = inject(DestroyRef);
  parkingSessions: BillingParkingSession[] = [];
  startOpen: boolean = false;

  orderedBy: 'name' | 'entry' | 'exit' = 'exit';
  orderedByOrder: 'asc' | 'desc' = 'desc';

  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<BillingResolve>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.route.data.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data) => {
      this.parkingSessions = sortArray([...data.billingApiResponse.unpaid_parking_sessions], (x) => x.exit_date_time, 'desc');
    });
  }

  ngAfterViewInit() {
    this.startOpen = window.innerWidth > breakPoints.md;
    this.cdr.detectChanges();
  }

  orderByName() {
    this.orderedByOrder = this.orderedBy === 'name' && this.orderedByOrder === 'asc' ? 'desc' : 'asc';
    this.orderedBy = 'name';

    sortArray(this.parkingSessions, (x) => x.garage_name, this.orderedByOrder);
  }

  orderByEntry() {
    this.orderedByOrder = this.orderedBy === 'entry' && this.orderedByOrder === 'asc' ? 'desc' : 'asc';
    this.orderedBy = 'entry';

    sortArray(this.parkingSessions, (x) => x.entry_date_time, this.orderedByOrder);
  }

  orderByExit() {
    this.orderedByOrder = this.orderedBy === 'exit' && this.orderedByOrder === 'asc' ? 'desc' : 'asc';
    this.orderedBy = 'exit';

    sortArray(this.parkingSessions, (x) => x.exit_date_time, this.orderedByOrder);
  }
}
