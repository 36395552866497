import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ArivoMarketingConsentService {
  constructor(private http: HttpClient) {}

  submitMarketingAgreement(email: string) {
    if (!environment.production) {
      console.warn('Marketing agreement not submitted in development environment');
      return;
    }
    if (!environment.featureFlags.enableMarketingConsent) {
      return;
    }

    const url = 'https://wipark.azurefd.net/v1/marketingagreement';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // eslint-disable-next-line
      'Ocp-Apim-Subscription-Key': '06f6cca3f2fd4d67b210085b92b8b1d1', // NOSONAR
    });
    const body = { email };

    this.http.post(url, body, { headers }).subscribe({
      next: (response) => {
        console.info('Marketing agreement submitted successfully', response);
      },
      error: (error) => {
        console.error('Error submitting marketing agreement', error);
      },
    });
  }
}
