import { Component, DestroyRef, inject, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { OnboardingService } from '../onboarding.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ArivoLicensePlateInputComponent } from '../../../../shared/components/ui-components/arivo-license-plate-input/arivo-license-plate-input.component';
import { ArivoStepperComponent } from '../../../../shared/components/ui-components/arivo-stepper/arivo-stepper.component';
import { LicensePlate } from '../../../../shared/models/licensePlate';
import { requireLicensePlate } from '../../../../shared/utils/form.utils';
import { validateLicensePlateBasic } from '../../../../shared/utils/license-plate.utils';
import { pairwise, take } from 'rxjs';
import { showSpinner } from '../../../../shared/utils/common.utils';

@Component({
  selector: 'app-onboarding-first-step',
  templateUrl: './onboarding-first-step.component.html',
  styleUrl: './onboarding-first-step.component.scss',
})
export class OnboardingFirstStepComponent {
  private _destroyRef = inject(DestroyRef);
  @ViewChild(ArivoLicensePlateInputComponent) licensePlateInputComponent!: ArivoLicensePlateInputComponent;

  @Input() stepperComponent?: ArivoStepperComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private onboardingService: OnboardingService,
  ) {
    this.licensePlateForm
      .get('licensePlate')
      ?.valueChanges.pipe(pairwise(), takeUntilDestroyed(this._destroyRef))
      .subscribe(([oldPlate, newPlate]: [LicensePlate | null, LicensePlate | null]) => {
        if (oldPlate?.license_plate !== newPlate?.license_plate || oldPlate?.license_plate_country !== newPlate?.license_plate_country) {
          if (newPlate) {
            this.updateUrlWithLicensePlate(newPlate);
          }
        }
      });
    this.setValuesFromUrl();
  }

  licensePlateForm = this._formBuilder.group({
    licensePlate: [<LicensePlate>{}, requireLicensePlate],
  });

  enterCheck(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.next();
    }
  }

  updateUrlWithLicensePlate(licensePlateObject: LicensePlate): void {
    if (this.stepperComponent?.selectedIndex !== 0) return;

    const licensePlate = licensePlateObject.license_plate;
    const licensePlateCountry = licensePlateObject.license_plate_country;

    const queryParams =
      licensePlate && licensePlateCountry ? { licensePlate, licensePlateCountry } : { licensePlate: null, licensePlateCountry: null };

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  setValuesFromUrl(): void {
    const params = this.route.snapshot.queryParams;
    const license_plate = params['licensePlate']?.toUpperCase();
    const license_plate_country = params['licensePlateCountry']?.toUpperCase();

    if (license_plate && license_plate_country) {
      if (!validateLicensePlateBasic(license_plate, license_plate_country)) {
        this.router.navigate([], {
          queryParams: { licensePlate: null, licensePlateCountry: null },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
        return;
      }
      this.licensePlateForm.setValue({
        licensePlate: {
          license_plate,
          license_plate_country,
        },
      });
    }
  }

  next(): void {
    this.licensePlateForm.markAllAsTouched();
    this.licensePlateInputComponent.markAllAsTouched();
    if (this.licensePlateForm.valid) {
      const licensePlate = this.licensePlateForm.value.licensePlate;
      this.onboardingService
        .getOnboardingSession(licensePlate as LicensePlate)
        .pipe(showSpinner(), take(1))
        .subscribe({
          next: () => {
            this.stepperComponent?.next();
          },
          error: (error) => {
            console.error(error);
          },
        });
    }
  }
}
