import { CanActivateFn, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { inject } from '@angular/core';
import { StorageManagerService } from '../services/storage-manager.service';

export const checkTokenValidity: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const storageManagerService = inject(StorageManagerService);
  return of(!!storageManagerService.load('token'));
};

export const checkTokenInvalidity: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const storageManagerService = inject(StorageManagerService);
  return of(!storageManagerService.load('token'));
};

export const checkAccessCardEnabled: CanActivateFn = (): Observable<boolean | UrlTree> => {
  return of(environment.featureFlags.enableAccessCard);
};
