import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { NavigationService } from '../../../../core/services/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LanguageService } from '../../../../core/services/language.service';
import { breakPoints, Language } from '../../../../core/utils/common.data';
import { AuthService } from '../../../../core/auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
  private _destroyRef = inject(DestroyRef);

  navigationOpen: boolean = false;

  windowResizing: boolean = false;

  showAccessCardTab: boolean = environment.featureFlags.enableAccessCard;

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private navigationService: NavigationService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.navigationService.navigationOpenStateSubject.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((value) => {
      this.navigationOpen = value;
    });

    // Disable navigation bar transition on window resize to prevent unnecessary animation
    window.addEventListener('resize', () => {
      this.windowResizing = true;

      if (window.innerWidth >= breakPoints.md) {
        this.closeNavigation();
      }
    });

    this.router.events.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.closeNavigation();
      }
    });
  }

  getCurrentLanguage(): string {
    return this.languageService.getCurrentLanguage();
  }

  changeLanguage(language: Language): void {
    this.languageService.setLanguage(language);
  }

  closeNavigation(): void {
    this.windowResizing = false;
    this.navigationService.navigationOpenStateSubject.next(false);
  }

  onLogOutClicked(): void {
    this.closeNavigation();
    this.authService.logOut();
  }
}
