<div class="md:ml-0 ml-auto md:mr-0 mr-auto card">
  <div class="text-center card-header">
    <div class="headline-small" [innerText]="'AccessCode.Title' | translate"></div>
  </div>
  @if (qrCodeLoading) {
    <div class="card-body flex justify-center">
      <div class="max-w-96 max-h-96 mb-2">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  } @else {
    @if (qrCode) {
      <div class="card-body flex justify-center">
        <div class="max-w-96 max-h-96">
          <img [src]="qrCode" alt="QR-Code" class="w-full h-full" />
        </div>
      </div>
      <div class="card-body text-center">
        <div class="body-medium text-decoration-dark" [innerHtml]="'AccessCode.Info' | translate"></div>
      </div>
    } @else {
      <div class="text-center card-body">
        <div class="body-large text-decoration-dark" [innerText]="'AccessCode.CurrentlyUnavailable' | translate"></div>
      </div>
    }
    <div class="card-body flex justify-center">
      <div class="max-w-96 max-h-96">
        <img [src]="'/assets/wipark/wipark_qr_help.jpg' | url" alt="QR-Info" class="w-auto h-full mx-auto" />
      </div>
    </div>
  }
</div>
