import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';

export const initializeSentry = () => {
  Sentry.init({
    enabled: environment.production,
    environment: environment.name,
    dsn: 'https://daec1181dae2127e1d88fd246c356d04@o4507142004408320.ingest.de.sentry.io/4507271145652304',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration({ failedRequestStatusCodes: [400, 403, 404, 418, 429, 500, 502, 503, 504] }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^http:\/\/localhost/,
      /^http:\/\/localhost:4200/,
      /^https:\/\/portal\.arivo\.fun/,
      /^https:\/\/wipark\.digimon\.arivo\.fun/,
      /^https:\/\/wipark-test\.arivo\.app/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
