import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-onboarding-email',
  templateUrl: './onboarding-email.component.html',
  styleUrl: './onboarding-email.component.scss',
})
export class OnboardingEmailComponent {
  @Input({ required: true }) email?: string;
  @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() sendAgainEvent: EventEmitter<void> = new EventEmitter<void>();
}
