<div class="card-responsive mb-6">
  <div class="card-header">
    <h5 class="headline-x-small">
      <i class="max-md:text-2xl fa-kit fa-mobile-phone"></i>
      <span [innerText]="'SignUp.AppSettings' | translate"></span>
    </h5>
  </div>
  <div class="card-body">
    <p class="mb-6 body-medium" [innerText]="'SignUp.ActivateNotificationsAndAddToHomeScreen' | translate"></p>
    <div class="flex flex-col gap-2">
      <div
        class="card flex items-center justify-between gap-4 cta-medium px-4 py-2 cursor-pointer"
        (click)="notificationsDialog.open = true"
      >
        <div class="flex items-center gap-4">
          <i class="text-2xl w-6 fa-light fa-bell-ring"></i>
          <p [innerText]="'SignUp.ActivateNotifications' | translate"></p>
        </div>
        <i class="text-xl fa-light fa-chevron-right"></i>
      </div>
      <div class="card flex items-center justify-between gap-4 cta-medium px-4 py-2 cursor-pointer" (click)="homeScreenDialog.open = true">
        <div class="flex items-center gap-4">
          <i class="text-2xl w-6 fa-kit fa-mobile-phone"></i>
          <p [innerText]="'SignUp.AddAppToHomeScreen' | translate"></p>
        </div>
        <i class="text-xl fa-light fa-chevron-right"></i>
      </div>
    </div>
  </div>
</div>

<arivo-step-footer>
  <button class="button-primary" [innerText]="'SignUp.Done' | translate" (click)="next()"></button>
  <button class="button-primary-outlined" [innerText]="'Common.Back' | translate" (click)="previous()"></button>
</arivo-step-footer>

<arivo-dialog #notificationsDialog>
  <div class="card">
    <div class="card-body">
      <p class="body-medium">
        Here are the instructions on how to activate notifications. This click should also request the required permissions.
      </p>
    </div>
  </div>
</arivo-dialog>

<arivo-dialog #homeScreenDialog>
  <div class="card">
    <div class="card-body">
      <p class="body-medium">Here are the instructions on how to add the app to the home screen.</p>
    </div>
  </div>
</arivo-dialog>
