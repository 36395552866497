import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ProductsApiResponse } from '../../shared/models/routeTyping';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from '../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ProductsApiService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  getProductsResponse(): Observable<ProductsApiResponse> {
    return this.http.get<ProductsApiResponse>(`/api/backoffice/v2/customer_portal/products/`).pipe(
      catchError((error) => {
        this.router.navigateByUrl('/dashboard');
        this.toastService.add({ type: 'error', message: this.translateService.instant('Common.Errors.PageCouldNotBeLoaded') });
        return of();
      }),
    );
  }
}
