import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { DashboardApiResponse } from '../../shared/models/routeTyping';
import { HttpClient } from '@angular/common/http';
import { parseDate } from '../../shared/utils/common.utils';
import { LicensePlate, PayPerUseLicensePlate } from '../../shared/models/licensePlate';
import { ParkingSession } from '../../shared/models/parkingSession';
import { AllowedCurrencies, Money } from '../../shared/models/common';
import { GarageContract } from '../../shared/models/contract';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from '../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  getDashboardApiResponse(): Observable<DashboardApiResponse> {
    return this.http.get<DashboardApiResponse>(`/api/backoffice/v2/customer_portal/dashboard_data/`).pipe(
      map((x) => deserializeDashboardApiResponse(x)),
      catchError((error) => {
        this.router.navigateByUrl('/error');
        this.toastService.add({ type: 'error', message: this.translateService.instant('Common.Errors.PageCouldNotBeLoaded') });
        return of();
      }),
    );
  }

  togglePayPerUseLicensePlate(licensePlate: PayPerUseLicensePlate): Observable<PayPerUseLicensePlate> {
    return this.http.put<PayPerUseLicensePlate>(`/api/backoffice/v2/customer_portal/pay_per_use/toggle/`, licensePlate);
  }

  addPayPerUseLicensePlate(licensePlate: LicensePlate): Observable<PayPerUseLicensePlate> {
    return this.http.post<PayPerUseLicensePlate>(`/api/backoffice/v2/customer_portal/pay_per_use/`, {
      license_plate: licensePlate.license_plate,
      license_plate_country: licensePlate.license_plate_country,
    });
  }
}

function deserializeDashboardApiResponse(response: any): DashboardApiResponse {
  response.garage_contracts = response.garage_contracts.map((contract: any) => {
    let updatedContract = {
      ...contract,
      start: parseDate(contract.start),
      price: {
        ...contract.price,
        amount: parseFloat(contract.price.amount),
      },
    };

    if (contract.end) {
      updatedContract = {
        ...updatedContract,
        end: parseDate(contract.end),
      };
    }
    return updatedContract;
  }) as GarageContract[];

  response.open_parking_sessions = response.open_parking_sessions.map((parkingSession: ParkingSession) => ({
    ...parkingSession,
    start_time: parseDate(parkingSession.start_time),
  })) as ParkingSession[];

  response.open_costs = response.open_costs.map((cost: { currency: AllowedCurrencies; amount: string }) => ({
    ...cost,
    amount: parseFloat(cost.amount),
  })) as Money[];

  return response;
}
