@if (!emailSent) {
  <div class="card-responsive card-responsive-small">
    <div class="card-body">
      <form [formGroup]="signUpForm" (ngSubmit)="next()">
        <div class="text-center">
          <h2 class="headline-x-small mb-6" [innerText]="'Onboarding.NewCustomerAccount' | translate"></h2>

          <arivo-license-plate
            [country]="licensePlate?.license_plate_country"
            [licensePlate]="licensePlate?.license_plate"
            [checkInvalid]="true"
          />
          <button
            class="button-primary-text mx-auto mt-2"
            type="button"
            (click)="previous()"
            [innerText]="'Common.Change' | translate"
          ></button>

          <div class="my-8">
            <div class="ar-input-group text-start">
              <div class="input-primary-container">
                <input id="email" type="email" class="input-primary mb-1" formControlName="email" addEmptyPlaceholder />
                <label for="email" class="label-primary" [innerText]="'Onboarding.EmailAddress' | translate"></label>
                <p class="error" *formError="'email.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
                <p class="error" *formError="'email.email'" [innerText]="'Common.Errors.ValidEmailIsRequired' | translate"></p>
              </div>
            </div>

            <p class="body-medium my-6" [innerText]="'Onboarding.PleaseProvideYourEmail' | translate"></p>

            <div class="inline-flex flex-col gap-4">
              <div class="ar-input-group ar-input-group-auto text-start">
                <div class="flex items-center gap-2">
                  <input id="termsAndConditions" type="checkbox" class="switch" formControlName="termsAndConditions" />
                  <label
                    for="termsAndConditions"
                    class="body-medium"
                    [innerHtml]="
                      'Onboarding.IHaveReadAndAcceptTheTermsAndConditions'
                        | translate: { url: 'https://www.wipark.at/o/document/nutzungsbedingungen_serviceportal' }
                    "
                  ></label>
                </div>
                <p
                  class="error"
                  *formError="'termsAndConditions.required'"
                  [innerText]="'Common.Errors.YouHaveToAcceptTheTermsAndConditions' | translate"
                ></p>
              </div>

              @if (marketingConsentEnabled) {
                <arivo-marketing-consent formControlName="marketingConsent"></arivo-marketing-consent>
              }
            </div>

            <p
              class="body-medium text-left mt-4 mb-6 ml-12"
              [innerHtml]="
                'Onboarding.AdditionalDataInformation' | translate: { url: 'https://www.wipark.at/unternehmen/datenschutzerklaerung' }
              "
            ></p>
          </div>
        </div>

        <arivo-step-footer [center]="true">
          <button type="submit" class="button-primary" [innerText]="'Onboarding.SendActivationLink' | translate"></button>
          <button class="button-primary-outlined" [routerLink]="['/login']" [innerText]="'Common.Cancel' | translate"></button>
        </arivo-step-footer>
      </form>
    </div>
  </div>
} @else {
  <div class="card-responsive card-horizontal">
    <div class="card-body text-left">
      <app-onboarding-email [email]="email" (cancelEvent)="emailSent = false" (sendAgainEvent)="next()" />
    </div>
    <div class="card-body text-left">
      <app-customer-service-info />
    </div>
  </div>
}
