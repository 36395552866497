import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContractLicensePlate, LicensePlate } from '../../../shared/models/licensePlate';
import { requireLicensePlate } from '../../../shared/utils/form.utils';
import { AbstractControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-contract-vehicles',
  templateUrl: './contract-vehicles.component.html',
  styleUrl: './contract-vehicles.component.scss',
})
export class ContractVehiclesComponent implements OnInit {
  enabledLicensePlates: number;

  @Input({ required: true }) userLicensePlates: ContractLicensePlate[] = [];
  customLicensePlates: ContractLicensePlate[] = [];

  @Input() vehicleQuota?: number | null;

  @Output() onLicensePlatesChanged: EventEmitter<LicensePlate[]> = new EventEmitter<LicensePlate[]>();

  constructor(private _formBuilder: FormBuilder) {
    this.enabledLicensePlates = this.licensePlates.filter((vehicle) => vehicle.enabled).length;
  }

  ngOnInit(): void {
    this._updateLicensePlates();
  }

  get licensePlates(): ContractLicensePlate[] {
    return this._licensePlates;
  }
  private _licensePlates: ContractLicensePlate[] = [];

  get showLicensePlateForm(): boolean {
    return this._showLicensePlateForm;
  }
  set showLicensePlateForm(value: boolean) {
    this._showLicensePlateForm = value;
    this.licensePlateForm.reset();
  }
  private _showLicensePlateForm: boolean = false;

  licensePlateForm = this._formBuilder.nonNullable.group({
    licensePlate: [<ContractLicensePlate>{}, [requireLicensePlate, this.uniqueLicensePlate.bind(this)]],
  });

  uniqueLicensePlate(control: AbstractControl) {
    if (
      this.licensePlates.some((licensePlate: ContractLicensePlate) => {
        return (
          licensePlate.license_plate_country === control.value.license_plate_country &&
          licensePlate.license_plate === control.value.license_plate
        );
      })
    ) {
      return { unique: true };
    }
    return null;
  }

  addLicensePlate(): void {
    this.licensePlateForm.markAllAsTouched();
    if (this.licensePlateForm.valid) {
      const licensePlate: ContractLicensePlate = {
        license_plate_country: this.licensePlateForm.value.licensePlate!.license_plate_country,
        license_plate: this.licensePlateForm.value.licensePlate!.license_plate,
        enabled: true,
      };

      this.customLicensePlates.push(licensePlate);
      this._licensePlates.push(licensePlate);

      this.showLicensePlateForm = false;
      this._updateLicensePlates();
    }
  }

  removeLicensePlate(index: number) {
    this.customLicensePlates.splice(index, 1);
    this._updateLicensePlates();
  }

  toggleLicensePlate(licensePlate: ContractLicensePlate) {
    licensePlate.enabled = !licensePlate.enabled;
    this._updateLicensePlates();
  }

  private _updateLicensePlates = (): void => {
    this._licensePlates = this.userLicensePlates.concat(this.customLicensePlates);
    this.enabledLicensePlates = this.licensePlates.filter((vehicle) => vehicle.enabled).length;
    this.onLicensePlatesChanged.emit(
      this._licensePlates
        .filter((licensePlate) => licensePlate.enabled)
        .map((licensePlate) => {
          return {
            license_plate: licensePlate.license_plate,
            license_plate_country: licensePlate.license_plate_country,
          };
        }),
    );
  };
}
