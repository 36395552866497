import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { BillingApiResponse } from '../../shared/models/routeTyping';
import { parseDate } from '../../shared/utils/common.utils';
import { BillingParkingSession } from '../../shared/models/parkingSession';
import { BillingInvoice } from '../../shared/models/invoice';
import { AllowedCurrencies, Money } from '../../shared/models/common';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from '../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BillingApiService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  getBillingApiResponse(): Observable<BillingApiResponse> {
    return this.http.get<BillingApiResponse>(`/api/invoice/v2/customer_portal/billing_data/`).pipe(
      map((x) => deserializeBillingApiResponse(x)),
      catchError((error) => {
        this.router.navigateByUrl('/dashboard');
        this.toastService.add({ type: 'error', message: this.translateService.instant('Common.Errors.PageCouldNotBeLoaded') });
        return of();
      }),
    );
  }
}

function deserializeBillingApiResponse(response: any): BillingApiResponse {
  response.open_costs = response.open_costs.map((cost: { currency: AllowedCurrencies; amount: string }) => ({
    ...cost,
    amount: parseFloat(cost.amount),
  })) as Money[];

  response.invoices = response.invoices.map((invoice: any) => {
    return {
      ...invoice,
      costs: {
        ...invoice.costs,
        amount: invoice.invoice_type === 'reversal' ? Number(invoice.costs.amount) * -1 : Number(invoice.costs.amount),
      },
      invoice_date: parseDate(invoice.invoice_date),
      period_start: parseDate(invoice.period_start),
      period_end: parseDate(invoice.period_end),
    };
  }) as BillingInvoice[];

  response.unpaid_parking_sessions = response.unpaid_parking_sessions.map((parkingSession: any) => ({
    ...parkingSession,
    price: {
      ...parkingSession.price,
      amount: Number(parkingSession.price.amount),
    },
    entry_date_time: parseDate(parkingSession.entry_date_time),
    exit_date_time: parseDate(parkingSession.exit_date_time),
  })) as BillingParkingSession[];
  return response;
}
