import { Directive, Host, Input, OnDestroy, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupName } from '@angular/forms';
import { map, merge, Observable, of, Subscription } from 'rxjs';

function getControl(
  nameAndError: string,
  form: ControlContainer,
): Observable<{
  error: string;
  control: FormControl | FormGroup;
}> {
  let [name, error] = nameAndError.split('.', 2);

  let control: FormControl | FormGroup | undefined = undefined;
  if (name === '') {
    control = (<any>form).form as FormGroup;
  } else if (form instanceof FormGroupName) {
    control = ((<any>form).control as FormGroup).get(name) as FormControl;
  } else {
    control = ((<any>form).form as FormGroup).get(name) as FormControl;
  }
  return merge(
    control.statusChanges.pipe(map(() => ({ error, control: control }))),
    of({ error, control: control }), // we omit once initially, so we check errors on init
  );
}

@Directive({
  selector: '[formError]',
})
export class FormErrorDirective implements OnDestroy {
  private onChange?: Subscription;
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    @Optional() @Host() private form: ControlContainer,
    private viewContainer: ViewContainerRef,
  ) {}

  @Input() set formError(nameAndError: string) {
    this.onChange?.unsubscribe();
    this.onChange = getControl(nameAndError, this.form).subscribe(({ error, control }) => this.updateView(error, control));
  }

  @Input() formErrorShowOnDirty: boolean = true;

  ngOnDestroy(): void {
    this.onChange?.unsubscribe();
    this.onChange = undefined;
  }

  updateView(error: string, control: FormControl | FormGroup) {
    let active = control.invalid && ((this.formErrorShowOnDirty && control.dirty) || control.touched);
    let errorValue = control.errors?.[error];
    let hideError = !(active && !!errorValue);

    if (!hideError && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (hideError && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}

@Directive({
  selector: '[formErrorText]',
})
export class FormErrorTextDirective implements OnDestroy {
  private onChange?: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    @Optional() @Host() private form: ControlContainer,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnDestroy(): void {
    this.onChange?.unsubscribe();
    this.onChange = undefined;
  }

  @Input() set formErrorText(nameAndError: string) {
    this.onChange?.unsubscribe();
    this.onChange = getControl(nameAndError, this.form).subscribe(({ error, control }) => this.updateView(error, control));
  }

  updateView(error: string, control: FormControl | FormGroup) {
    let active = control.invalid && (control.dirty || control.touched);
    let errorValue = control.errors?.[error];
    let hideError = !(active && !!errorValue);

    this.viewContainer.clear();
    if (errorValue && !hideError) {
      errorValue = Array.isArray(errorValue) ? errorValue : [errorValue];
      for (let error of errorValue) {
        let view = this.viewContainer.createEmbeddedView(this.templateRef);
        view.rootNodes[0].innerText = '' + error;
      }
    }
  }
}
