import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { ProductsComponent } from './features/products/products.component';
import { BillingComponent } from './features/billing/billing.component';
import { HelpComponent } from './features/help/help.component';
import { LoggedInLayoutComponent } from './shared/components/layouts/logged-in-layout/logged-in-layout.component';
import { PublicLayoutComponent } from './shared/components/layouts/public-layout/public-layout.component';
import { LoginComponent } from './features/login/login.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { DashboardApiService } from './features/dashboard/dashboard-api.service';
import {
  BillingResolve,
  ContractCreateResolve,
  ContractEditResolve,
  CoreDataResolve,
  DashboardResolve,
  OnboardingFinishResolve,
  ProductsResolve,
  TypedResolve,
  UserResolve,
  VehiclesResolve,
} from './shared/models/routeTyping';
import { CoreDataService } from './core/services/core-data.service';
import { ContractCreateComponent } from './features/contracts/contract-create/contract-create.component';
import { ContractsApiService } from './features/contracts/contracts-api.service';
import { TermsAndConditionsComponent } from './features/legal-information/terms-and-conditions/terms-and-conditions.component';
import { OnboardingComponent } from './features/signup/onboarding/onboarding.component';
import { OnboardingFinishComponent } from './features/signup/onboarding-finish/onboarding-finish.component';
import { DataPrivacyComponent } from './features/legal-information/data-privacy/data-privacy.component';
import { ContractEditComponent } from './features/contracts/contract-edit/contract-edit.component';
import { BillingApiService } from './features/billing/billing-api.service';
import { VehiclesComponent } from './features/vehicles/vehicles.component';
import { VehiclesApiService } from './features/vehicles/vehicles-api.service';
import { UserComponent } from './features/user/user.component';
import { checkAccessCardEnabled, checkTokenInvalidity, checkTokenValidity } from './core/auth/auth-guard.service';
import { ErrorPageComponent } from './features/error-page/error-page.component';
import { ProductsApiService } from './features/products/products-api.service';
import { ImprintRedirectComponent } from './features/legal-information/imprint-redirect/imprint-redirect.component';
import { AccessCardComponent } from './features/access-card/access-card.component';
import { UserService } from './features/user/user.service';
import { OnboardingFinishApiService } from './features/signup/onboarding-finish/onboarding-finish-api.service';

const routes: Routes = [
  {
    path: 'imprint',
    component: ImprintRedirectComponent,
  },
  {
    path: '',
    component: LoggedInLayoutComponent,
    resolve: {
      core_data: () => inject(CoreDataService).fetchCoreData(),
    } as TypedResolve<CoreDataResolve>,
    canMatch: [checkTokenValidity],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        resolve: {
          dashboardApiResponse: () => inject(DashboardApiService).getDashboardApiResponse(),
        } as TypedResolve<DashboardResolve>,
      },
      {
        path: 'contracts',
        children: [
          {
            path: 'create',
            component: ContractCreateComponent,
            resolve: {
              contractCreateApiResponse: () => inject(ContractsApiService).getContractCreateApiResponse(),
            } as TypedResolve<ContractCreateResolve>,
          },
          {
            path: 'edit/:contractId',
            component: ContractEditComponent,
            resolve: {
              contractEditApiResponse: (route: ActivatedRouteSnapshot) => {
                const id = route.paramMap.get('contractId')!;
                return inject(ContractsApiService).getContractEditApiResponse(id);
              },
            } as TypedResolve<ContractEditResolve>,
          },
          {
            path: '**',
            redirectTo: '/dashboard',
          },
        ],
      },
      {
        path: 'products',
        component: ProductsComponent,
        resolve: {
          productsApiResponse: () => inject(ProductsApiService).getProductsResponse(),
        } as TypedResolve<ProductsResolve>,
      },
      {
        path: 'vehicles',
        component: VehiclesComponent,
        resolve: {
          vehiclesApiResponse: () => inject(VehiclesApiService).getVehiclesResponse(),
        } as TypedResolve<VehiclesResolve>,
      },
      {
        path: 'billing',
        component: BillingComponent,
        resolve: {
          billingApiResponse: () => inject(BillingApiService).getBillingApiResponse(),
        } as TypedResolve<BillingResolve>,
      },
      {
        path: 'onboarding/finish',
        component: OnboardingFinishComponent,
        resolve: {
          onboardingFinishApiResolve: () => inject(OnboardingFinishApiService).getOnboardingResponse(),
        } as TypedResolve<OnboardingFinishResolve>,
      },
      {
        path: 'data',
        component: UserComponent,
        resolve: {
          user: () => inject(UserService).getUserResponse(),
        } as TypedResolve<UserResolve>,
      },
      {
        path: 'help',
        component: HelpComponent,
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
      },
      {
        path: 'data-privacy',
        component: DataPrivacyComponent,
      },
      {
        path: 'access-card',
        component: AccessCardComponent,
        canMatch: [checkAccessCardEnabled],
      },
      {
        path: 'error',
        component: ErrorPageComponent,
      },
    ],
  },
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
      {
        path: 'onboarding',
        component: OnboardingComponent,
        canMatch: [checkTokenInvalidity],
      },
      {
        path: 'onboarding/:token',
        component: OnboardingComponent,
        resolve: {
          token: (route: ActivatedRouteSnapshot) => route.paramMap.get('token'),
        },
      },
      {
        path: 'login',
        component: LoginComponent,
        canMatch: [checkTokenInvalidity],
      },
      {
        path: 'login/:token',
        component: LoginComponent,
        resolve: {
          token: (route: ActivatedRouteSnapshot) => route.paramMap.get('token'),
        },
      },
      {
        path: 'data-privacy',
        component: DataPrivacyComponent,
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
      },
      {
        path: 'help',
        component: HelpComponent,
      },
      {
        path: 'error',
        component: ErrorPageComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    canMatch: [checkTokenValidity],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
