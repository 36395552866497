<div class="info mb-6">
  <p class="body-medium" [innerText]="'Billing.ShortTermParkingInformation' | translate"></p>
</div>

@if (parkingSessions.length > 0) {
  <!-- Mobile -->
  <div class="xl:hidden">
    @for (parkingSession of parkingSessions | slice: paginator.startIndex : paginator.endIndex; track $index) {
      <arivo-accordion class="border-gray-200 border-1 mb-4 last:mb-0" [open]="startOpen">
        <arivo-accordion-header [caretLeft]="true">
          <div class="flex gap-2 w-full body-medium">
            @if ((parkingSession.entry_date_time | date: 'shortDate') === (parkingSession.exit_date_time | date: 'shortDate')) {
              <div>
                {{ parkingSession.entry_date_time | localizedDate: 'mediumDate' }}
              </div>
            } @else {
              <div>
                {{ parkingSession.entry_date_time | localizedDate: 'mediumDate' }} -
                {{ parkingSession.exit_date_time | localizedDate: 'mediumDate' }}
              </div>
            }
            <div class="ml-auto text-interaction-dark">
              {{ parkingSession.price.amount | localizedCurrency: parkingSession.price.currency }}
            </div>
          </div>
        </arivo-accordion-header>
        <arivo-accordion-body class="parking-session-accordion-body">
          <div class="flex items-start justify-between flex-wrap gap-3 mb-4">
            <div class="flex items-start body-large">
              <i class="fa-kit fa-parking text-decoration text-xl mr-2"></i>
              <span class="max-md:mt-0.5" [innerText]="parkingSession.garage_name"></span>
            </div>
            <arivo-license-plate
              [country]="parkingSession.license_plate.license_plate_country"
              [licensePlate]="parkingSession.license_plate.license_plate"
              [compact]="true"
            ></arivo-license-plate>
          </div>

          <div class="flex flex-wrap gap-4 body-medium">
            @if ((parkingSession.entry_date_time | date: 'shortDate') !== (parkingSession.exit_date_time | localizedDate: 'shortDate')) {
              <div class="flex-1 flex-shrink-0">
                <div class="text-neutral-50 whitespace-nowrap">
                  <i class="fa-light fa-arrow-right-to-bracket w-4 mr-1.5"></i>
                  <span [innerText]="'Billing.ParkingSessions.Entry' | translate"></span>
                </div>
                <div class="text-neutral-30 whitespace-nowrap" [innerText]="parkingSession.entry_date_time | localizedDate: 'short'"></div>
              </div>

              <div class="flex-1 flex-shrink-0">
                <div class="text-neutral-50 whitespace-nowrap">
                  <i class="fa-light fa-arrow-left-from-bracket w-4 mr-1.5"></i>
                  <span [innerText]="'Billing.ParkingSessions.Exit' | translate"></span>
                </div>
                <div class="text-neutral-30 whitespace-nowrap" [innerText]="parkingSession.exit_date_time | localizedDate: 'short'"></div>
              </div>
            } @else {
              <div class="flex-1 flex-shrink-0">
                <div class="text-neutral-50 whitespace-nowrap">
                  <i class="fa-light fa-arrow-right-to-bracket w-4 mr-1.5"></i>
                  <span [innerText]="'Billing.ParkingSessions.Entry' | translate"></span>
                </div>
                <div
                  class="text-neutral-30 whitespace-nowrap"
                  [innerText]="parkingSession.entry_date_time | localizedDate: 'shortTime'"
                ></div>
              </div>

              <div class="flex-1 flex-shrink-0">
                <div class="text-neutral-50 whitespace-nowrap">
                  <i class="fa-light fa-arrow-left-from-bracket w-4 mr-1.5"></i>
                  <span [innerText]="'Billing.ParkingSessions.Exit' | translate"></span>
                </div>
                <div
                  class="text-neutral-30 whitespace-nowrap"
                  [innerText]="parkingSession.exit_date_time | localizedDate: 'shortTime'"
                ></div>
              </div>
            }

            <div class="flex-1 flex-shrink-0">
              <div class="text-neutral-50 whitespace-nowrap">
                <i class="fa-light fa-hourglass-clock w-4 mr-1.5"></i>
                <span [innerText]="'Billing.ParkingSessions.Duration' | translate"></span>
              </div>
              <div
                class="text-neutral-30 whitespace-nowrap"
                [innerHtml]="parkingSession.entry_date_time | dateDiff: parkingSession.exit_date_time | duration: 'minutes' : 'short'"
              ></div>
            </div>
          </div>
        </arivo-accordion-body>
      </arivo-accordion>
    }
  </div>

  <!-- Desktop -->
  <div class="hidden xl:block max-w-full overflow-x-auto">
    <table class="table-primary">
      <thead class="body-medium">
        <tr>
          <th class="cursor-pointer" (click)="orderByName()">
            <span class="font-medium" [innerText]="'Billing.ParkingSessions.Location' | translate"></span>
            <span class="ml-2">
              @if (orderedBy === 'name') {
                @if (orderedByOrder === 'asc') {
                  <i class="fa-light fa-arrow-down"></i>
                } @else {
                  <i class="fa-light fa-arrow-up"></i>
                }
              } @else {
                <i class="fa-light fa-arrow-down-arrow-up text-neutral-70"></i>
              }
            </span>
          </th>
          <th>
            <span class="font-medium" [innerText]="'Billing.ParkingSessions.Vehicle' | translate"></span>
          </th>
          <th class="cursor-pointer" (click)="orderByEntry()">
            <span class="font-medium" [innerText]="'Billing.ParkingSessions.Entry' | translate"></span>
            <span class="ml-2">
              @if (orderedBy === 'entry') {
                @if (orderedByOrder === 'asc') {
                  <i class="fa-light fa-arrow-down"></i>
                } @else {
                  <i class="fa-light fa-arrow-up"></i>
                }
              } @else {
                <i class="fa-light fa-arrow-down-arrow-up text-neutral-70"></i>
              }
            </span>
          </th>
          <th class="cursor-pointer" (click)="orderByExit()">
            <span class="font-medium" [innerText]="'Billing.ParkingSessions.Exit' | translate"></span>
            <span class="ml-2">
              @if (orderedBy === 'exit') {
                @if (orderedByOrder === 'asc') {
                  <i class="fa-light fa-arrow-down"></i>
                } @else {
                  <i class="fa-light fa-arrow-up"></i>
                }
              } @else {
                <i class="fa-light fa-arrow-down-arrow-up text-neutral-70"></i>
              }
            </span>
          </th>
          <th>
            <span class="font-medium" [innerText]="'Billing.ParkingSessions.Duration' | translate"></span>
          </th>
          <th>
            <div class="text-right">
              <span class="font-medium" [innerText]="'Billing.ParkingSessions.Amount' | translate"></span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        @for (parkingSession of parkingSessions | slice: paginator.startIndex : paginator.endIndex; track $index) {
          <tr class="body-medium">
            <td [innerText]="parkingSession.garage_name"></td>
            <td>
              <arivo-license-plate
                [country]="parkingSession.license_plate.license_plate_country"
                [licensePlate]="parkingSession.license_plate.license_plate"
              ></arivo-license-plate>
            </td>
            <td [innerText]="parkingSession.entry_date_time | localizedDate: 'short'"></td>
            <td [innerText]="parkingSession.exit_date_time | localizedDate: 'short'"></td>
            <td [innerHtml]="parkingSession.entry_date_time | dateDiff: parkingSession.exit_date_time | duration: 'minutes' : 'short'"></td>
            <td>
              <div class="text-right" [innerText]="parkingSession.price.amount | localizedCurrency: parkingSession.price.currency"></div>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>

  <div class="flex justify-end mt-4">
    <arivo-paginator #paginator [items]="parkingSessions" />
  </div>
} @else {
  <div class="text-center my-6">
    <div class="text-neutral-50 text-2xl">
      <i class="fa-kit fa-time"></i>
    </div>
    <div class="body-medium mt-2">
      <span class="text-neutral-50" [innerText]="'Billing.ParkingSessions.NoSessions' | translate"></span>
    </div>
  </div>
}
