import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageManagerService } from '../services/storage-manager.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private storageManagerService: StorageManagerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Exclude requests to local assets
    if (request.url.startsWith('assets') || request.url.startsWith('https://wipark.azurefd.net/v1/marketingagreement')) {
      return next.handle(request);
    }

    let headers: { [key: string]: string } = {};

    const token = this.storageManagerService.load('token');
    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }
    headers['Accept-Language'] = this.storageManagerService.load('language') ?? 'en';

    request = request.clone({
      setHeaders: headers,
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }
}
