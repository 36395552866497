<arivo-alerts></arivo-alerts>

<div class="headline-small mb-4" [innerText]="'UserData.ProfileSettings' | translate"></div>
<form [formGroup]="contactDetailsForm" (ngSubmit)="handleSubmit()">
  <div class="card-responsive md:mb-4 mb-8">
    <div class="card-header">
      <h5 class="headline-x-small">
        <i class="max-md:text-2xl fa-kit fa-data"></i>
        <span [innerText]="'UserData.BillingAddress' | translate"></span>
      </h5>
    </div>
    <div class="card-body">
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="email" class="input-primary" addEmptyPlaceholder formControlName="email" />
          <label for="email" [innerText]="'UserData.Email' | translate"></label>
        </div>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="firstName" class="input-primary" addEmptyPlaceholder formControlName="first_name" />
          <label for="firstName" [innerText]="'UserData.FirstName' | translate"></label>
        </div>
        <p class="error text-left" *formError="'first_name.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
        <p
          class="error text-left"
          *formError="'first_name.serverError'"
          [innerText]="contactDetailsForm.get('first_name')?.errors?.['serverError']"
        ></p>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="lastName" class="input-primary" addEmptyPlaceholder formControlName="last_name" />
          <label for="lastName" [innerText]="'UserData.LastName' | translate"></label>
        </div>
        <p class="error text-left" *formError="'last_name.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
        <p
          class="error text-left"
          *formError="'last_name.serverError'"
          [innerText]="contactDetailsForm.get('last_name')?.errors?.['serverError']"
        ></p>
      </div>
      <div class="mb-2 flex flex-col md:flex-row">
        <div class="ar-input-group flex-grow md:mr-2 mb-2 md:mb-0">
          <div class="input-primary-container">
            <input id="street" class="input-primary" addEmptyPlaceholder formControlName="street" />
            <label for="street" [innerText]="'UserData.Street' | translate"></label>
            <p class="error text-left" *formError="'street.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
            <p
              class="error text-left"
              *formError="'street.serverError'"
              [innerText]="contactDetailsForm.get('street')?.errors?.['serverError']"
            ></p>
          </div>
        </div>
        <div class="ar-input-group md:w-1/4">
          <div class="input-primary-container">
            <input id="streetNumber" class="input-primary" addEmptyPlaceholder formControlName="street_number" />
            <label for="streetNumber" [innerText]="'UserData.StreetNumber' | translate"></label>
            <p
              class="error text-left"
              *formError="'street_number.required'"
              [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"
            ></p>
            <p
              class="error text-left"
              *formError="'street_number.serverError'"
              [innerText]="contactDetailsForm.get('street_number')?.errors?.['serverError']"
            ></p>
          </div>
        </div>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="postalCode" class="input-primary" addEmptyPlaceholder formControlName="zip_code" />
          <label for="postalCode" [innerText]="'UserData.PostalCode' | translate"></label>
        </div>
        <p class="error text-left" *formError="'zip_code.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
        <p
          class="error text-left"
          *formError="'zip_code.serverError'"
          [innerText]="contactDetailsForm.get('zip_code')?.errors?.['serverError']"
        ></p>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="city" class="input-primary" addEmptyPlaceholder formControlName="city" />
          <label for="city" [innerText]="'UserData.City' | translate"></label>
        </div>
        <p class="error text-left" *formError="'city.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
        <p
          class="error text-left"
          *formError="'city.serverError'"
          [innerText]="contactDetailsForm.get('city')?.errors?.['serverError']"
        ></p>
      </div>
      <div class="ar-input-group mb-6">
        <div class="input-primary-container">
          <select id="country" class="input-primary" formControlName="country">
            @for (country of selectableCountries; track country.iso2Code) {
              <option
                [value]="country.iso2Code"
                [innerText]="'CountryNames.' + country.name | translate"
                *ngIf="country.iso2Code !== 'XX'"
              ></option>
            }
          </select>
          <label for="country" [innerText]="'UserData.Country' | translate"></label>
        </div>
        <p class="error text-left" *formError="'country.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
        <p
          class="error text-left"
          *formError="'country.serverError'"
          [innerText]="contactDetailsForm.get('country')?.errors?.['serverError']"
        ></p>
      </div>
      <div class="flex items-center gap-2">
        <input id="businessAccount" type="checkbox" class="switch" formControlName="business" />
        <label for="businessAccount" class="body-medium" [innerText]="'UserData.BusinessAccount' | translate"></label>
      </div>

      @if (contactDetailsForm.value.business) {
        <div class="ar-input-group mb-2 mt-6">
          <div class="input-primary-container">
            <input id="companyName" class="input-primary" formControlName="company_name" addEmptyPlaceholder />
            <label for="companyName" [innerText]="'UserData.CompanyName' | translate"></label>
          </div>
          <p class="error text-left" *formError="'company_name.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
          <p
            class="error text-left"
            *formError="'company_name.serverError'"
            [innerText]="contactDetailsForm.get('company_name')?.errors?.['serverError']"
          ></p>
        </div>
        <div class="ar-input-group mb-2">
          <div class="input-primary-container">
            <input id="vat_id" class="input-primary" addEmptyPlaceholder formControlName="vat_id" />
            <label for="vat_id" [innerText]="'UserData.UIDOptional' | translate"></label>
          </div>
          <p
            class="error text-left"
            *formError="'vat_id.serverError'"
            [innerText]="contactDetailsForm.get('vat_id')?.errors?.['serverError']"
          ></p>
        </div>
      }
    </div>
  </div>

  <arivo-step-footer>
    <button class="button-primary min-w-24" [innerText]="'Common.Save' | translate" type="submit"></button>
  </arivo-step-footer>
</form>

@if (appAndNotificationsEnabled) {
  <div class="card-responsive mt-8">
    <div class="card-header">
      <h5 class="headline-x-small">
        <i class="max-md:text-2xl fa-kit fa-mobile-phone"></i>
        <span [innerText]="'SignUp.AppSettings' | translate"></span>
      </h5>
    </div>
    <div class="card-body">
      <p class="mb-6 body-medium" [innerText]="'SignUp.ActivateNotificationsAndAddToHomeScreen' | translate"></p>
      <div class="flex flex-col gap-2">
        <div
          class="card flex items-center justify-between gap-4 cta-medium px-4 py-2 cursor-pointer"
          (click)="notificationsDialog.open = true"
        >
          <div class="flex items-center gap-4">
            <i class="text-2xl w-6 fa-light fa-bell-ring"></i>
            <p [innerText]="'SignUp.ActivateNotifications' | translate"></p>
          </div>
          <i class="text-xl fa-light fa-chevron-right"></i>
        </div>
        <div
          class="card flex items-center justify-between gap-4 cta-medium px-4 py-2 cursor-pointer"
          (click)="homeScreenDialog.open = true"
        >
          <div class="flex items-center gap-4">
            <i class="text-2xl w-6 fa-kit fa-mobile-phone"></i>
            <p [innerText]="'SignUp.AddAppToHomeScreen' | translate"></p>
          </div>
          <i class="text-xl fa-light fa-chevron-right"></i>
        </div>
      </div>
    </div>
  </div>

  <arivo-dialog #notificationsDialog>
    <div class="card">
      <div class="card-body">
        <p class="body-medium">
          Here are the instructions on how to activate notifications. This click should also request the required permissions.
        </p>
      </div>
    </div>
  </arivo-dialog>

  <arivo-dialog #homeScreenDialog>
    <div class="card">
      <div class="card-body">
        <p class="body-medium">Here are the instructions on how to add the app to the home screen.</p>
      </div>
    </div>
  </arivo-dialog>
}
