import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingService } from './core/services/loading.service';
import { environment } from '../environments/environment';
import { initializeSentry } from '../sentry';
import { StorageManagerService } from './core/services/storage-manager.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  loading: boolean = false;

  showCookieDialog: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private storageManagerService: StorageManagerService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
  ) {
    if (environment.featureFlags.enableCookieConsent && !this.storageManagerService.load('cookiePreferences')) {
      this.showCookieDialog = true;
    }
  }

  ngOnInit() {
    this.loadingService.loadingChanges().subscribe((loading) => {
      this.loading = loading;
      this.cdr.detectChanges();
    });
    this.titleService.setTitle(environment.titleName ?? 'Arivo Serviceportal');
  }

  rejectNonEssentialCookies() {
    const cookiePreferences = {
      functionalCookies: false,
    };
    this.storageManagerService.store('cookiePreferences', cookiePreferences);

    this.showCookieDialog = false;
  }

  acceptAllCookies() {
    const cookiePreferences = {
      functionalCookies: true,
    };
    this.storageManagerService.store('cookiePreferences', cookiePreferences);

    if (cookiePreferences.functionalCookies) {
      initializeSentry();
    }

    this.showCookieDialog = false;
  }
}
