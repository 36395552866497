import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'arivo-marketing-consent',
  templateUrl: './arivo-marketing-consent.component.html',
  styleUrl: './arivo-marketing-consent.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ArivoMarketingConsentComponent),
      multi: true,
    },
  ],
})
export class ArivoMarketingConsentComponent implements ControlValueAccessor {
  constructor() {}

  public regOnChange = (_: any) => {};

  public regOnTouched = (_: any) => {};

  registerOnChange(fn: any): void {
    this.regOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.regOnTouched = fn;
  }

  writeValue(_: any) {}

  onChange($event: any) {
    this.regOnChange($event.target.checked);
  }
}
