import { Component, DestroyRef, inject, Input } from '@angular/core';
import { OnboardingService } from '../onboarding.service';
import { LicensePlate } from '../../../../shared/models/licensePlate';
import { ArivoStepperComponent } from '../../../../shared/components/ui-components/arivo-stepper/arivo-stepper.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SignupParkingSession } from '../../../../shared/models/parkingSession';

@Component({
  selector: 'app-onboarding-second-step',
  templateUrl: './onboarding-second-step.component.html',
  styleUrl: './onboarding-second-step.component.scss',
})
export class OnboardingSecondStepComponent {
  @Input() stepperComponent?: ArivoStepperComponent;

  destroyRef = inject(DestroyRef);
  parkingSession: SignupParkingSession | null = null;

  constructor(private _onboardingService: OnboardingService) {
    this._onboardingService.onboardingSession.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((session) => {
      this.parkingSession = session;
    });
  }

  get licensePlate(): LicensePlate | null {
    return this._onboardingService.licensePlate.getValue();
  }

  previous() {
    if (this.stepperComponent) {
      this.stepperComponent.previous();
    }
  }

  next() {
    if (this.stepperComponent) {
      this.stepperComponent.next();
    }
  }
}
