import { Component, DestroyRef, inject, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { ContractCreateResolve } from '../../../shared/models/routeTyping';
import { map, Observable } from 'rxjs';
import { Garage } from '../../../shared/models/garage';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterService } from '../../../shared/services/router.service';

@Component({
  selector: 'app-contract-create',
  templateUrl: './contract-create.component.html',
  styleUrl: './contract-create.component.scss',
})
export class ContractCreateComponent {
  garagesObservable: Observable<Garage[]>;
  bookableContractsAvailable: boolean = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    private router: Router,
    private routerService: RouterService,
    @Inject(ActivatedRoute) private route: TypedRoute<ContractCreateResolve>,
  ) {
    this.garagesObservable = this.route.data.pipe(
      map((data) => {
        return data.contractCreateApiResponse.garages.filter((garage) =>
          garage.contract_templates.some((template) => template.bookable === 'public' || template.bookable === 'information'),
        );
      }),
    );

    this.garagesObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((garages) => {
      this.bookableContractsAvailable = garages.length > 0;
    });
  }

  back(): void {
    this.router.navigateByUrl(this.routerService.previousUrl ?? '/dashboard');
  }
}
