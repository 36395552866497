import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { initializeSentry } from './sentry';

if (environment.featureFlags.enableCookieConsent) {
  try {
    const cookiePreferences = JSON.parse(localStorage.getItem('cookiePreferences') || '{}');
    if (cookiePreferences?.functionalCookies) {
      initializeSentry();
    }
  } catch {
    // Do nothing
    // We shouldn't load Sentry if cookie preferences can not be retrieved
  }
} else {
  initializeSentry();
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
