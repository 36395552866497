import { Component, Input } from '@angular/core';
import { Garage } from '../../../shared/models/garage';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-garage-details',
  templateUrl: './garage-details.component.html',
  styleUrl: './garage-details.component.scss',
})
export class GarageDetailsComponent {
  @Input() garage?: Garage;

  creatingContractEnabled = environment.featureFlags.enableContractCreation;

  constructor() {}

  get bookableContractTemplates() {
    return this.garage?.contract_templates?.filter((template) => template.bookable === 'public') || [];
  }

  get informationContractTemplates() {
    return this.garage?.contract_templates?.filter((template) => template.bookable === 'information') || [];
  }

  formatAddress(garage: Garage): string {
    return `${garage.street} ${garage.number}, ${garage.zip_code} ${garage.city}`;
  }
}
