<h2 class="headline-small text-center mb-4" [innerText]="'Contracts.EditContract' | translate"></h2>
<form [formGroup]="contractForm" (ngSubmit)="finish()">
  <div class="card-responsive mb-4">
    <div class="card-header">
      <h5 class="headline-x-small">
        <i class="max-md:text-2xl fa-kit fa-contract"></i>
        <span [innerText]="'Contracts.ContractDetails' | translate"></span>
      </h5>
    </div>
    <div class="card-body text-left">
      <div class="well flex items-center justify-between gap-2 w-[100%] mb-4">
        <div class="flex-none text-success-dark text-3xl">
          <i class="fa-kit fa-parking"></i>
        </div>
        <div *ngIf="contract.garage" class="flex-1 flex flex-col md:flex-row md:items-center justify-between gap-2 md:gap-4">
          <div class="flex-1 text-left">
            <h5 class="body-large mb-0.5" [innerText]="contract.garage.name"></h5>
            <h6 class="body-small text-neutral-50">
              {{ contract.garage.street }} {{ contract.garage.number }}, {{ contract.garage.zip_code }} {{ contract.garage.city }}
            </h6>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-between mb-4">
        <div>
          <div class="body-medium text-neutral-50" [innerText]="'Dashboard.Contracts.Contract' | translate"></div>
          <div class="body-medium text-neutral-10" [innerText]="contract.name"></div>
        </div>
        <div class="inline-flex">
          <div class="ml-2 body-large">
            <i class="fa-kit fa-parking-space mr-1.5"></i>
            <span [innerText]="contract.group_limit"></span>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-between mb-1">
        <div class="body-medium text-neutral-50" [innerText]="'Dashboard.Contracts.MonthlyPrice' | translate"></div>
        <div
          class="body-medium text-neutral-10 text-right"
          [innerText]="contract.price.amount | localizedCurrency: contract.price.currency"
        ></div>
      </div>
      <div class="flex items-center justify-between">
        @if (contract.end) {
          <div class="body-medium text-neutral-50" [innerText]="'Dashboard.Contracts.ContractDuration' | translate"></div>
        } @else {
          <div class="body-medium text-neutral-50" [innerText]="'Dashboard.Contracts.ContractDurationSince' | translate"></div>
        }
        <div class="body-medium text-neutral-10 text-right">
          {{ contract.start | localizedDate }} {{ contract.start && contract.end ? '-' : '' }} {{ contract.end | localizedDate }}
        </div>
      </div>

      <hr class="my-6" />

      <h5 class="body-large text-decoration-dark text-left mb-2" [innerText]="'Contracts.EligibleVehicles' | translate"></h5>
      <div class="ar-input-group text-left">
        <app-contract-vehicles
          [userLicensePlates]="userLicensePlates"
          [vehicleQuota]="contract.group_limit"
          (onLicensePlatesChanged)="updateLicensePlates($event)"
        />
      </div>
    </div>
  </div>

  <hr class="mb-6 mt-4 md:hidden" />

  <div class="flex flex-col-reverse md:flex-row md:items-center justify-between gap-4">
    @if (enableContractTermination && !contract.end) {
      <button
        class="flex button-danger-outlined"
        type="button"
        (click)="terminateContractDialog.open = true"
        [innerText]="'Contracts.TerminateContract' | translate"
      ></button>
    }
    <arivo-step-footer class="flex-1">
      <button class="button-primary" type="submit" [innerText]="'Common.SaveChanges' | translate"></button>
      <button class="button-primary-text" type="button" (click)="back()" [innerText]="'Common.Cancel' | translate"></button>
    </arivo-step-footer>
  </div>
</form>

<arivo-bottom-sheet-dialog #terminateContractDialog (onOpen)="resetTerminationDate()" [dynamicWidth]="true">
  <h5 class="headline-x-small mb-2" [innerText]="'Contracts.TerminateContract' | translate"></h5>
  <div class="w-full flex justify-center">
    <mat-calendar
      class="max-md:min-w-[320px] min-w-[400px] max-w-[500px] flex-grow"
      #endDatepicker
      [(selected)]="selectedTerminationDate"
      [dateFilter]="endDatepickerFilter"
      [startAt]="selectedTerminationDate"
    ></mat-calendar>
  </div>
  <hr class="my-5" />
  <div class="w-full flex justify-center body-large">Selected termination date: {{ selectedTerminationDate | date }}</div>
  <hr class="my-5" />
  <div class="w-full flex justify-center">
    <div class="flex justify-between w-full gap-2">
      <button
        class="button-primary-outlined w-full"
        type="button"
        (click)="terminateContractDialog.open = false"
        [innerText]="'Common.Cancel' | translate"
      ></button>
      <button class="button-danger w-full" (click)="terminateContract()" [innerText]="'Contracts.TerminateContract' | translate"></button>
    </div>
  </div>
</arivo-bottom-sheet-dialog>
