<div class="tabs">
  <ul class="tab-group">
    <li *ngFor="let tab of tabs">
      <button class="select-none" (click)="selectTab(tab)" [class.active]="tab.active">
        <div *ngIf="tab.icon" class="text-xl text-decoration-dark">
          <i [class]="tab.icon"></i>
        </div>
        <span class="body-medium text-decoration-dark">{{ tab.title }}</span>
      </button>
    </li>
  </ul>
  <div class="card">
    <div class="card-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
