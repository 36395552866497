import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { ProductsComponent } from './products/products.component';
import { BillingComponent } from './billing/billing.component';
import { HelpComponent } from './help/help.component';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginEmailComponent } from './login/login-email/login-email.component';
import { CustomerServiceInfoComponent } from './login/customer-service-info/customer-service-info.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardPayPerUseComponent } from './dashboard/dashboard-pay-per-use/dashboard-pay-per-use.component';
import { DashboardOpenParkingSessionsComponent } from './dashboard/dashboard-open-parking-sessions/dashboard-open-parking-sessions.component';
import { DashboardContractsComponent } from './dashboard/dashboard-contracts/dashboard-contracts.component';
import { DynamicContractsGridDirective } from './dashboard/dashboard-contracts/dynamic-contracts-grid.directive';
import { ContractCreateComponent } from './contracts/contract-create/contract-create.component';
import { ContractCreateFirstStepComponent } from './contracts/contract-create/contract-create-first-step/contract-create-first-step.component';
import { ContractCreateSecondStepComponent } from './contracts/contract-create/contract-create-second-step/contract-create-second-step.component';
import { DashboardAccountBalanceComponent } from './dashboard/dashboard-account-balance/dashboard-account-balance.component';
import { TermsAndConditionsComponent } from './legal-information/terms-and-conditions/terms-and-conditions.component';
import { OnboardingComponent } from './signup/onboarding/onboarding.component';
import { OnboardingFirstStepComponent } from './signup/onboarding/onboarding-first-step/onboarding-first-step.component';
import { OnboardingSecondStepComponent } from './signup/onboarding/onboarding-second-step/onboarding-second-step.component';
import { OnboardingThirdStepComponent } from './signup/onboarding/onboarding-third-step/onboarding-third-step.component';
import { OnboardingFinishComponent } from './signup/onboarding-finish/onboarding-finish.component';
import { OnboardingFinishFirstStepComponent } from './signup/onboarding-finish/onboarding-finish-first-step/onboarding-finish-first-step.component';
import { OnboardingFinishSecondStepComponent } from './signup/onboarding-finish/onboarding-finish-second-step/onboarding-finish-second-step.component';
import { OnboardingFinishThirdStepComponent } from './signup/onboarding-finish/onboarding-finish-third-step/onboarding-finish-third-step.component';
import { OnboardingFinishTransferComponent } from './signup/onboarding-finish/onboarding-finish-transfer/onboarding-finish-transfer.component';
import { DataPrivacyComponent } from './legal-information/data-privacy/data-privacy.component';
import { ContractEditComponent } from './contracts/contract-edit/contract-edit.component';
import { ContractVehiclesComponent } from './contracts/contract-vehicles/contract-vehicles.component';
import { BillingAccountBalanceComponent } from './billing/billing-account-balance/billing-account-balance.component';
import { BillingParkingSessionsComponent } from './billing/billing-park-sessions/billing-parking-sessions.component';
import { BillingInvoicesComponent } from './billing/billing-invoices/billing-invoices.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { UserComponent } from './user/user.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { GarageDetailsComponent } from './products/garage-details/garage-details.component';
import { ImprintRedirectComponent } from './legal-information/imprint-redirect/imprint-redirect.component';
import { AccessCardComponent } from './access-card/access-card.component';
import { NgOptimizedImage } from '@angular/common';
import { OnboardingEmailComponent } from './signup/onboarding/onboarding-third-step/onboarding-email/onboarding-email.component';

@NgModule({
  declarations: [
    ProductsComponent,
    BillingComponent,
    HelpComponent,
    OnboardingComponent,
    OnboardingFirstStepComponent,
    OnboardingSecondStepComponent,
    OnboardingThirdStepComponent,
    OnboardingFinishComponent,
    OnboardingFinishFirstStepComponent,
    OnboardingFinishSecondStepComponent,
    OnboardingFinishThirdStepComponent,
    OnboardingFinishTransferComponent,
    LoginComponent,
    LoginEmailComponent,
    CustomerServiceInfoComponent,
    DashboardComponent,
    DashboardPayPerUseComponent,
    DashboardOpenParkingSessionsComponent,
    DashboardContractsComponent,
    DynamicContractsGridDirective,
    ContractCreateComponent,
    ContractCreateFirstStepComponent,
    ContractCreateSecondStepComponent,
    DashboardAccountBalanceComponent,
    TermsAndConditionsComponent,
    DataPrivacyComponent,
    ContractEditComponent,
    ContractVehiclesComponent,
    BillingAccountBalanceComponent,
    BillingParkingSessionsComponent,
    BillingInvoicesComponent,
    VehiclesComponent,
    UserComponent,
    ErrorPageComponent,
    GarageDetailsComponent,
    ImprintRedirectComponent,
    AccessCardComponent,
    OnboardingEmailComponent,
  ],
  imports: [CoreModule, RouterModule, SharedModule, NgOptimizedImage],
  exports: [],
})
export class FeaturesModule {}
