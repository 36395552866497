<app-header />
<div class="flex max-w-full">
  <div class="w-auto">
    <app-sidebar />
  </div>
  <div class="content w-full">
    <router-outlet />
  </div>
</div>

<arivo-dialog-container />
