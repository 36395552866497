<div class="ar-input-group ar-input-group-auto text-start">
  <div class="flex items-center gap-2">
    <input id="marketingDeclaration" type="checkbox" class="switch" (change)="onChange($event)" (blur)="regOnTouched($event)" />
    <label
      for="marketingDeclaration"
      class="body-medium"
      [innerHtml]="
        'Onboarding.IHaveReadAndAcceptTheMarketingDeclaration'
          | translate: { url: 'https://www.wipark.at/unternehmen/datenschutzerklaerung', email: 'datenschutz@wipark.at' }
      "
    ></label>
  </div>
</div>
