export const environment = {
  name: 'staging',
  titleName: 'Staging Serviceportal',
  production: true,
  featureFlags: {
    enableMarketingConsent: true,
    enableContractCreation: true,
    enableContractQrCode: true,
    enableAccessCard: true,
    enableContractTermination: true,
    enableAppAndNotifications: false,
    enableCookieConsent: false,
  },
  developmentFlags: {
    enableDemoLogin: true,
    enableDemoLoginOnly: false,
    enableMockCalls: true,
    backendUrl: 'https://wipark.digimon.arivo.fun',
    devAdyenBackendUrl: 'http://localhost:3000',
    requireCardHolderName: true,
  },
};
