import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SignupParkingSession } from '../../../shared/models/parkingSession';
import { OnboardingService } from '../onboarding/onboarding.service';
import { LPRCountryCode } from '../../../shared/models/country';
import { showSpinner } from '../../../shared/utils/common.utils';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-onboarding-finish',
  templateUrl: './onboarding-finish.component.html',
  styleUrl: './onboarding-finish.component.scss',
})
export class OnboardingFinishComponent {
  parkingSession: SignupParkingSession | null = null;

  showParkingSessionOverview: boolean = true;
  appAndNotificationsEnabled = environment.featureFlags.enableAppAndNotifications;

  constructor(
    private route: ActivatedRoute,
    private onboardingService: OnboardingService,
  ) {
    this.route.queryParamMap.subscribe((queryParams) => {
      const licensePlate = queryParams.get('license_plate');
      const licensePlateCountry = queryParams.get('license_plate_country');

      if (licensePlate && licensePlateCountry) {
        this.onboardingService
          .getOnboardingSession({
            license_plate: licensePlate,
            license_plate_country: licensePlateCountry as LPRCountryCode,
          })
          .pipe(showSpinner())
          .subscribe((data) => {
            this.parkingSession = data;

            if (!this.parkingSession) {
              this.showParkingSessionOverview = false;
            }
          });
      } else {
        this.showParkingSessionOverview = false;
      }
    });
  }
}
