import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { GarageContract, GarageContractCreate, GarageContractTerminate, GarageContractUpdate } from '../../shared/models/contract';
import { Router } from '@angular/router';
import { ContractCreateApiResponse, ContractEditApiResponse } from '../../shared/models/routeTyping';
import { HttpClient } from '@angular/common/http';
import { parseDate, showSpinner } from '../../shared/utils/common.utils';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ContractsApiService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  getContractCreateApiResponse(): Observable<ContractCreateApiResponse> {
    return this.http.get<ContractCreateApiResponse>(`/api/backoffice/v2/customer_portal/contract/create/`).pipe(
      map((x) => deserializeContractCreateResolver(x)),
      catchError((error) => {
        this.router.navigateByUrl('/dashboard');
        this.toastService.add({ type: 'error', message: this.translateService.instant('Common.Errors.PageCouldNotBeLoaded') });
        return of();
      }),
    );
  }

  getContractEditApiResponse(id: string): Observable<ContractEditApiResponse> {
    return this.http
      .get<ContractEditApiResponse>(`/api/backoffice/v2/customer_portal/contract/${id}/update/`, { observe: 'response' })
      .pipe(
        map((response) => {
          if (response.status === 200) {
            return deserializeContractEditApiResponse(response.body as ContractEditApiResponse);
          }
          throw response;
        }),
        catchError((error) => {
          if (error.status === 404) {
            this.router.navigate(['/dashboard']);
            this.toastService.add({ type: 'error', message: this.translateService.instant('Contracts.ContractNotFound') });
            return of();
          }
          throw error;
        }),
      );
  }

  createContract(contract: GarageContractCreate): Observable<GarageContract> {
    return this.http.post<any>(`/api/backoffice/v2/customer_portal/contract/`, contract).pipe(showSpinner());
  }

  updateContract(contract: GarageContractUpdate, id: GarageContract['id']): Observable<GarageContract> {
    return this.http.put<GarageContract>(`/api/backoffice/v2/customer_portal/contract/${id}/license_plates/`, contract).pipe(showSpinner());
  }

  terminateContract(contract: GarageContractTerminate, id: GarageContract['id']): Observable<GarageContract> {
    return this.http.put<GarageContract>(`/api/backoffice/v2/garage_contract/${id}/terminate/`, contract).pipe(showSpinner());
  }
}

function deserializeContractCreateResolver(response: any): ContractCreateApiResponse {
  return {
    ...response,
    garages: response.garages.map((garage: any) => ({
      ...garage,
      contract_templates: garage.contract_templates.map((template: any) => ({
        ...template,
        price: {
          ...template.price,
          amount: parseFloat(template.price.amount),
        },
      })),
    })),
  };
}

function deserializeContractEditApiResponse(response: any): ContractEditApiResponse {
  response.garage_contract = {
    ...response.garage_contract,
    price: {
      ...response.garage_contract.price,
      amount: parseFloat(response.garage_contract.price.amount),
    },
    start: parseDate(response.garage_contract.start),
    end: response.garage_contract.end ? parseDate(response.garage_contract.end) : null,
  };
  return response;
}
