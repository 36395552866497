import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TypedRoute } from '../../../utils/router.utils';
import { CoreDataResolve } from '../../../models/routeTyping';
import { ArivoDialogComponent } from '../../ui-components/arivo-dialog/arivo-dialog.component';
import { DialogService } from '../../../services/dialog.service';
import { CoreDataService, WarningData } from '../../../../core/services/core-data.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-logged-in-layout',
  templateUrl: './logged-in-layout.component.html',
  styleUrl: './logged-in-layout.component.scss',
})
export class LoggedInLayoutComponent implements OnInit {
  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<CoreDataResolve>,
    private router: Router,
    private dialogService: DialogService,
    private coreDataService: CoreDataService,
  ) {}

  @ViewChild('homeScreenDialog') homeScreenDialog!: ArivoDialogComponent;
  redirectByPaymentVerification: boolean = false;
  isOnboardingRoute: boolean = false;
  isUserDataRoute: boolean = false;

  ngOnInit() {
    this.route.data.subscribe({
      next: (data: CoreDataResolve) => {
        this.coreDataService.setCoreData(data.core_data);

        this.redirectByPaymentVerification = !!this.route.snapshot.queryParams['redirectResult'];
        this.isOnboardingRoute = this.router.url.includes('/onboarding');
        this.isUserDataRoute = this.router.url.includes('/data');

        this.coreDataService.warningData.pipe(take(1)).subscribe({
          next: (warningData: WarningData) => {
            if (!this.redirectByPaymentVerification) {
              if (warningData.banStatus) {
                this.handleBanStatus(warningData);
              } else {
                this.handleWarningData(warningData);
              }
            }
          },
        });
      },
    });
  }

  handleBanStatus(warningData: WarningData) {
    if (warningData.banStatus!.active) {
      if (warningData.banStatus!.reason === 'due_payment') {
        this.dialogService.showPaymentFailedDialog();
      } else if (warningData.banStatus!.reason === 'missing_payment_method') {
        this.dialogService.showMissingPaymentMethodBannedDialog();
      } else if (warningData.banStatus!.reason === 'other') {
        this.dialogService.showBannedDialog();
      }
    } else {
      this.dialogService.showBanImminentDialog();
    }
  }

  handleWarningData(warningData: WarningData) {
    if (!this.isOnboardingRoute) {
      if (warningData.paymentMissing && warningData.customerAddressMissing) {
        this.dialogService.showFinishRegistrationReminderDialog();
      } else if (warningData.paymentMissing) {
        this.dialogService.showPaymentMethodReminderDialog();
      } else if (warningData.customerAddressMissing && !this.isUserDataRoute) {
        this.dialogService.showCustomerAddressReminderDialog();
      }
    }
  }
}
