import { FormBuilder, Validators } from '@angular/forms';
import { fbOptionalString } from '../../../../shared/utils/form.utils';
import { ArivoStepperComponent } from '../../../../shared/components/ui-components/arivo-stepper/arivo-stepper.component';
import { Garage } from '../../../../shared/models/garage';
import { ActivatedRoute, Router } from '@angular/router';
import { TypedRoute } from '../../../../shared/utils/router.utils';
import { ContractCreateResolve } from '../../../../shared/models/routeTyping';
import { ContractCreateService } from '../contract-create.service';
import { AfterContentInit, Component, DestroyRef, inject, Inject, Input, OnInit } from '@angular/core';
import { Observable, pairwise } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterService } from '../../../../shared/services/router.service';

@Component({
  selector: 'app-contract-create-first-step',
  templateUrl: './contract-create-first-step.component.html',
  styleUrl: './contract-create-first-step.component.scss',
})
export class ContractCreateFirstStepComponent implements OnInit, AfterContentInit {
  @Input() stepperComponent!: ArivoStepperComponent;
  @Input() garagesObservable!: Observable<Garage[]>;

  private destroyRef = inject(DestroyRef);

  garages: Garage[] = [];

  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private routerService: RouterService,
    private contractCreateService: ContractCreateService,
    @Inject(ActivatedRoute) private route: TypedRoute<ContractCreateResolve>,
  ) {}

  ngOnInit() {
    this.garagesObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((garages) => {
      this.garages = garages;
    });

    // Subscribe to changes in the garage selection
    this.contractForm
      .get('garage')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .pipe(pairwise())
      .subscribe(([oldId, newId]) => {
        if (oldId !== newId) {
          this.updateProductSelection(newId);
        }
      });
    this.contractForm
      .get('product')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .pipe(pairwise())
      .subscribe(([oldId, newId]) => {
        if (oldId !== newId) {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              productId: newId,
            },
            queryParamsHandling: 'merge',
          });
        }
      });
  }

  ngAfterContentInit() {
    if (!this.garages || this.garages.length === 0) {
      this.setDefaultGarageAndProduct();
    }
    const garageId = this.route.snapshot.queryParams['garageId'];
    const productId = this.route.snapshot.queryParams['productId'];
    const selectedGarage = this.garages.find((garage) => garage.id === garageId);
    if (selectedGarage) {
      const selectedProduct = selectedGarage.contract_templates.find((template) => template.id === productId);
      this.contractForm.patchValue({ garage: garageId });
      this.updateProductSelection(garageId);
      if (selectedProduct && selectedProduct.bookable === 'public') {
        this.contractForm.patchValue({ product: productId });
        this.continue();
      }
    } else {
      this.setDefaultGarageAndProduct();
    }
  }

  get garage(): Garage | undefined {
    return this.garages.find((garage) => garage.id === this.contractForm.value.garage);
  }

  get bookableContractTemplates() {
    return this.garage?.contract_templates?.filter((template) => template.bookable === 'public') || [];
  }

  get informationContractTemplates() {
    return this.garage?.contract_templates?.filter((template) => template.bookable === 'information') || [];
  }

  contractForm = this._formBuilder.nonNullable.group({
    garage: [fbOptionalString, Validators.required],
    product: [fbOptionalString, Validators.required],
  });

  setDefaultGarageAndProduct(): void {
    if (!this.contractForm.value.garage && this.garages.length > 0) {
      this.contractForm.patchValue({ garage: this.garages[0].id });
      this.updateProductSelection(this.garages[0].id);
    }
  }

  updateProductSelection(selectedGarageId: Garage['id'] | null): void {
    const selectedGarage = this.garages.find((garage) => garage.id === selectedGarageId);
    if (selectedGarage) {
      const publicTemplates = selectedGarage.contract_templates.filter((template) => template.bookable === 'public');
      if (publicTemplates.length > 0) {
        this.contractForm.patchValue({ product: publicTemplates[0].id });
      } else {
        this.contractForm.patchValue({ product: null });
      }

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          garageId: selectedGarage.id,
          productId: publicTemplates.length > 0 ? publicTemplates[0].id : null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  back(): void {
    this.router.navigateByUrl(this.routerService.previousUrl ?? '/dashboard');
  }

  continue(): void {
    this.contractForm.markAllAsTouched();
    if (this.contractForm.valid) {
      const garage = this.garages.find((garage) => garage.id === this.contractForm.value.garage);
      const product = garage?.contract_templates.find((product) => product.id === this.contractForm.value.product);

      if (garage && product) {
        this.contractCreateService.garageSubject.next(garage);
        this.contractCreateService.productSubject.next(product);
      }

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          garageId: this.contractForm.value.garage,
          productId: this.contractForm.value.product,
        },
        queryParamsHandling: 'merge',
      });
      this.stepperComponent.next();
    }
  }
}
