import { Injectable } from '@angular/core';
import { Garage } from '../../../shared/models/garage';
import { ContractTemplate } from '../../../shared/models/contractTemplate';
import { map, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AllowedCurrencies, Money } from '../../../shared/models/common';
import { GarageContract } from '../../../shared/models/contract';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

export interface ContractTemplateInfo {
  discount: {
    type: string;
    value: Money;
  } | null;
  price: Money;
  vat_percent: number;
  earliest_termination_date: string;
  first_payment_period: {
    price: Money;
    start: string;
    end: string;
  };
}

interface ContractTemplateInfoResponse {
  name: string;
  group_limit: number;
  start: string;
  end: string | null;
  discount_type: string;
  discount_amount: string;
  piece_price: string;
  price: string;
  price_currency: AllowedCurrencies;
  vat_percent: string;
  first_termination_date: string;
  first_payment_period: {
    price: string;
    start: string;
    end: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ContractCreateService {
  garageSubject: Subject<Garage> = new Subject<Garage>();
  productSubject: Subject<ContractTemplate> = new Subject<ContractTemplate>();

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  getContractTemplateInfo(
    id: ContractTemplate['id'],
    group_limit: GarageContract['group_limit'],
    start: string,
  ): Observable<ContractTemplateInfo> {
    return this.http
      .get<ContractTemplateInfoResponse>(
        `/api/backoffice/v2/garage_contract/preview/?contract_template_id=${id}&group_limit=${group_limit}&start=${start}`,
      )
      .pipe(
        map((x) => this.deserializeContractTemplateInfo(x)),
        catchError((error) => {
          this.toastService.add({ type: 'error', message: this.translateService.instant('Common.Errors.PageCouldNotBeLoaded') });
          return of();
        }),
      );
  }

  deserializeContractTemplateInfo(response: ContractTemplateInfoResponse): ContractTemplateInfo {
    return {
      discount: response.discount_type
        ? { type: response.discount_type, value: { amount: parseFloat(response.discount_amount), currency: response.price_currency } }
        : null,
      price: { amount: parseFloat(response.price), currency: response.price_currency },
      vat_percent: parseFloat(response.vat_percent),
      earliest_termination_date: response.first_termination_date,
      first_payment_period: {
        price: { amount: parseFloat(response.first_payment_period.price), currency: response.price_currency },
        start: response.first_payment_period.start,
        end: response.first_payment_period.end,
      },
    };
  }
}
