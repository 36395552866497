import { PaymentType } from '../../shared/models/common';

export const exampleOpenCosts: any[] = [
  {
    amount: '47.39',
    currency: 'EUR',
  },
];

export const exampleLicensePlates: any[] = [
  {
    license_plate_country: 'D',
    license_plate: 'D:EMO1',
  },
  {
    license_plate_country: 'A',
    license_plate: 'D:EMO2',
  },
  {
    license_plate_country: 'D',
    license_plate: 'D:EMO3',
  },
];

export const examplePayPerUseLicensePlates: any[] = [
  {
    license_plate_country: 'D',
    license_plate: 'D:EMO1',
    enabled: true,
  },
  {
    license_plate_country: 'A',
    license_plate: 'D:EMO2',
    enabled: true,
  },
  {
    license_plate_country: 'D',
    license_plate: 'D:EMO3',
    enabled: false,
  },
];

export const exampleGarageContracts: any[] = [
  {
    id: '1',
    bookable: 'internal',
    name: '24h Dauerparken (Beispielvertrag)',
    product_id: '1',
    garage: {
      name: 'Freyung Parkgarage',
      street: 'Herrengasse',
      number: '',
      zip_code: '1010',
      city: 'Wien',
    },
    group_limit: 2,
    license_plates: [...exampleLicensePlates],
    price: { amount: '100.12', currency: 'EUR' },
    start: '2022-01-01',
    end: '2022-12-12',
    minimum_duration_months: 12,
    notice_period_months: 1,
  },
  {
    id: '2',
    bookable: 'information',
    name: 'Nachtparken (Beispielvertrag)',
    product_id: '2',
    garage: {
      name: 'Liechtensteinstraße 4 Parkgarage',
      street: 'Liechtensteinstraße',
      number: '4',
      zip_code: '1090',
      city: 'Wien',
    },
    group_limit: 1,
    license_plates: [exampleLicensePlates[0], exampleLicensePlates[1]],
    price: { amount: '150', currency: 'EUR' },
    start: '2024-07-30T14:00:00',
    end: null,
    minimum_duration_months: 12,
    notice_period_months: 1,
  },
  {
    id: '3',
    bookable: 'public',
    name: 'Wochenendparken (Beispielvertrag)',
    product_id: '3',
    garage: {
      name: 'Gonzagagasse Parkgarage',
      street: 'Gonzagagasse',
      number: '21',
      zip_code: '1010',
      city: 'Wien',
    },
    group_limit: 1,
    license_plates: [exampleLicensePlates[0]],
    price: { amount: '150', currency: 'EUR' },
    start: '2022-01-01T14:00:00',
    minimum_duration_months: 6,
    notice_period_months: 3,
  },
];

export const exampleUser: any = {
  email: 'example.email@arivo.co',
  first_name: null,
  last_name: null,
  address: null,
  zip_code: null,
  city: null,
  country: null,
  business: false,
  company_name: null,
  uid: null,
};

export const exampleProducts: any[] = [
  {
    id: '1',
    name: '24h Dauerparken (Beispielvertrag)',
    description: null,
    price: {
      amount: '98.0',
      currency: 'EUR',
    },
    bookable: 'public',
    min_contract_period: 12,
    termination_period: 1,
  },
  {
    id: '2',
    name: 'Nachtparken (Beispielvertrag)',
    description: 'Parken von 18:00 bis 08:00 Uhr',
    price: {
      amount: '50.0',
      currency: 'EUR',
    },
    bookable: 'public',
    min_contract_period: 6,
    termination_period: 1,
  },
  {
    id: '3',
    name: 'Wochenendparken (Beispielvertrag)',
    description: 'Parken von Freitag 18:00 bis Montag 08:00 Uhr',
    price: {
      amount: '30.0',
      currency: 'EUR',
    },
    bookable: 'public',
    min_contract_period: null,
    termination_period: 1,
  },
  {
    id: '4',
    name: 'Neuer Infovertrag (Beispielvertrag)',
    description: 'Parken nur als Information',
    price: {
      amount: '30.0',
      currency: 'EUR',
    },
    bookable: 'information',
    min_contract_period: null,
    termination_period: 1,
  },
];

export const exampleGarages: any[] = [
  {
    id: '1',
    name: 'Freyung Parkgarage',
    street: 'Herrengasse',
    number: '',
    zip_code: '1010',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.21155881631771,
      longitude: 16.365026225916996,
    },
  },
  {
    id: '2',
    name: 'Liechtensteinstraße 4 Parkgarage',
    street: 'Liechtensteinstraße',
    number: '4',
    zip_code: '1090',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.21604840903735,
      longitude: 16.363832630426497,
    },
  },
  {
    id: '3',
    name: 'Gonzagagasse Parkgarage',
    street: 'Gonzagagasse',
    number: '21',
    zip_code: '1010',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.216355040623974,
      longitude: 16.36921650392693,
    },
  },
  {
    id: '4',
    name: 'Beethovenplatz Parkgarage',
    street: 'Beethovenplatz',
    number: '3',
    zip_code: '1010',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.20222576206016,
      longitude: 16.377300737639715,
    },
  },
  {
    id: '5',
    name: 'Parkring Parkgarage',
    street: 'Parkring',
    number: '12a',
    zip_code: '1010',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.205231652061826,
      longitude: 16.376602057955086,
    },
  },
  {
    id: '6',
    name: 'Weihburggasse Parkgarage',
    street: 'Hegelgasse',
    number: '1',
    zip_code: '1010',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.20464917761038,
      longitude: 16.37668393748485,
    },
  },
  {
    id: '7',
    name: 'Naschmarkt Parkgarage',
    street: 'Kühnplatz',
    number: '1',
    zip_code: '1040',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.19773299356564,
      longitude: 16.364905578794318,
    },
  },
  {
    id: '8',
    name: 'Technische Uni Operngasse Parkgarage',
    street: 'Operngasse',
    number: '13',
    zip_code: '1040',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.19892138190959,
      longitude: 16.366609336042092,
    },
  },
  {
    id: '9',
    name: 'TU Campus Gußhaus Parkgarage',
    street: 'Gußhausstraße',
    number: '27',
    zip_code: '1040',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.196308662484185,
      longitude: 16.36969202370014,
    },
  },
  {
    id: '10',
    name: 'Technische Uni Lehargasse Parkgarage',
    street: 'Lehargasse',
    number: '4',
    zip_code: '1060',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.20041530069149,
      longitude: 16.363593033587133,
    },
  },
  {
    id: '11',
    name: 'Stiftgasse Parkhaus',
    street: 'Stiftgasse',
    number: '5-9',
    zip_code: '1070',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.20095290136897,
      longitude: 16.355348113071994,
    },
  },
  {
    id: '12',
    name: 'Westbahnhof Parkhaus',
    street: 'Felberstraße',
    number: '1',
    zip_code: '1150',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.19702700095822,
      longitude: 16.33537348325772,
    },
  },
  {
    id: '13',
    name: 'Viktoriagasse Parkhaus',
    street: 'Viktoriagasse',
    number: '4',
    zip_code: '1150',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.193929657461034,
      longitude: 16.332402255553905,
    },
  },
  {
    id: '14',
    name: 'Garage U3 Hütteldorfer Straße',
    street: 'Hütteldorfer Straße',
    number: '112',
    zip_code: '1140',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.19919041094897,
      longitude: 16.30990067039236,
    },
  },
  {
    id: '15',
    name: 'Pflegewohnhaus Baumgarten Parkgarage',
    street: 'Seckendorfstraße',
    number: '1',
    zip_code: '1140',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.19738950638981,
      longitude: 16.28691621485978,
    },
  },
  {
    id: '16',
    name: 'Hugo Breitner Hof Parkgarage',
    street: 'Hanakgasse',
    number: '2-4',
    zip_code: '1140',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.19810686469938,
      longitude: 16.269587952878368,
    },
  },
  {
    id: '17',
    name: 'Missindorfstraße Parkgarage',
    street: 'Missindorfstraße',
    number: '21-23',
    zip_code: '1140',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.19629043256003,
      longitude: 16.30581650575736,
    },
  },
  {
    id: '18',
    name: 'P+R Liesing Parkhaus',
    street: 'Liesinger Platz',
    number: '1',
    zip_code: '1230',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.1356875192668,
      longitude: 16.28373674111286,
    },
  },
  {
    id: '19',
    name: 'P+R Siebenhirten Parkhaus',
    street: 'Porschestraße',
    number: '25',
    zip_code: '1230',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.129327442656866,
      longitude: 16.31040398538774,
    },
  },
  {
    id: '20',
    name: 'LKH Mödling Parkplatz',
    street: 'Gabrieler Straße',
    number: '13',
    zip_code: '2340',
    city: 'Mödling',
    contract_templates: [],
    location: {
      latitude: 48.08818277101867,
      longitude: 16.295344576222263,
    },
  },
  {
    id: '21',
    name: 'Star 22 Parkgarage',
    street: 'Adelheid-Popp-Gasse',
    number: '22',
    zip_code: '1220',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.22905579837852,
      longitude: 16.458544386138968,
    },
  },
  {
    id: '22',
    name: 'Seestadt Aspern Parkgarage P3',
    street: 'Frenkel-Brunswik-Gasse',
    number: '1-3',
    zip_code: '1220',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.2241238683289,
      longitude: 16.502898701297816,
    },
  },
  {
    id: '23',
    name: 'Seestadt Aspern Parkgarage P4',
    street: 'Ilse-Arlt-Straße',
    number: '4-6',
    zip_code: '1220',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.22429702066207,
      longitude: 16.49804191657782,
    },
  },
  {
    id: '24',
    name: 'Seestadt Aspern Parkgarage P1',
    street: 'Schenk-Danzinger-Gasse',
    number: '4-6',
    zip_code: '1220',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.224841994543304,
      longitude: 16.500955979234085,
    },
  },
  {
    id: '25',
    name: 'Seestadt Aspern Parkgarage P2',
    street: 'Agnes-Primocic-Gasse',
    number: '4',
    zip_code: '1220',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.22580109647777,
      longitude: 16.501246671109058,
    },
  },
  {
    id: '26',
    name: 'Enkplatz Parkgarage',
    street: 'Enkplatz',
    number: '',
    zip_code: '1110',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.176321939728965,
      longitude: 16.41383812154674,
    },
  },
  {
    id: '27',
    name: 'Simmeringer Hauptstraße Parkgarage',
    street: 'Simmeringer Hauptstraße',
    number: '108a',
    zip_code: '1110',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.17234207413262,
      longitude: 16.417948388370878,
    },
  },
  {
    id: '28',
    name: 'Simmering U3 Parkgarage',
    street: 'Simmeringer Hauptstraße',
    number: '171',
    zip_code: '1110',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.16926847626746,
      longitude: 16.422548233372925,
    },
  },
  {
    id: '29',
    name: 'Gerhard-Bronner-Straße Parkgarage',
    street: 'Gerhard-Bronner-Straße',
    number: '7',
    zip_code: '1100',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.18343455615321,
      longitude: 16.378587180316323,
    },
  },
  {
    id: '30',
    name: 'Hauptbahnhof Wien Parkgarage',
    street: 'Gerhard-Bronner-Straße',
    number: '2 Hauptbahnhof',
    zip_code: '1100',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.184132818096764,
      longitude: 16.378732772940403,
    },
  },
  {
    id: '31',
    name: 'Neu Leopoldau P1 Parkplatz',
    street: 'Thayagasse',
    number: '3',
    zip_code: '1210',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.27350117629031,
      longitude: 16.430141742135167,
    },
  },
  {
    id: '32',
    name: 'Neu Leopoldau P2 Parkhaus',
    street: 'Simmelgasse',
    number: '2',
    zip_code: '1210',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.27657421102725,
      longitude: 16.428687645868738,
    },
  },
  {
    id: '33',
    name: 'Neu Leopoldau P3 Parkhaus',
    street: 'Tauschekgasse',
    number: '11-13',
    zip_code: '1210',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.27583398792935,
      longitude: 16.431648705589378,
    },
  },
  {
    id: '34',
    name: 'Dornerplatz Parkgarage',
    street: 'Dornerplatz',
    number: '',
    zip_code: '1170',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.22091488327673,
      longitude: 16.33404503461344,
    },
  },
  {
    id: '35',
    name: 'Geblergasse Parkgarage',
    street: 'Geblergasse',
    number: '107',
    zip_code: '1170',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.21697542510072,
      longitude: 16.32313335747462,
    },
  },
  {
    id: '36',
    name: 'Hofferplatz Parkgarage',
    street: 'Hofferplatz',
    number: '',
    zip_code: '1160',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.208920513789536,
      longitude: 16.333715740600187,
    },
  },
  {
    id: '37',
    name: 'Unizentrum Althanstraße Parkhaus',
    street: 'Augasse',
    number: '2-6',
    zip_code: '1090',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.23303650876524,
      longitude: 16.358395691079554,
    },
  },
  {
    id: '38',
    name: 'Pharmazie Parkhaus',
    street: 'Augasse',
    number: '2-6',
    zip_code: '1090',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.232596683667744,
      longitude: 16.358673023604464,
    },
  },
  {
    id: '39',
    name: 'Town Town Parkgarage',
    street: 'Schnirchgasse',
    number: '12',
    zip_code: '1030',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.19452585935334,
      longitude: 16.410568025820044,
    },
  },
  {
    id: '40',
    name: 'Windmühlgasse Parkhaus',
    street: 'Windmühlgasse',
    number: '22',
    zip_code: '1060',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.19894145278572,
      longitude: 16.356126998312025,
    },
  },
  {
    id: '41',
    name: 'Media Quarter Marx Parkgarage',
    street: 'Henneberggasse',
    number: '2-4',
    zip_code: '1030',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.189726490083366,
      longitude: 16.40505551635187,
    },
  },
  {
    id: '42',
    name: 'Media Quarter Marx Parkplatz',
    street: 'Maria-Jacobi-Gasse',
    number: '2',
    zip_code: '1030',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.18853110572543,
      longitude: 16.404239370268183,
    },
  },
  {
    id: '43',
    name: 'Tangente U3 Parkplatz',
    street: 'Im Erdberger Mais',
    number: '3',
    zip_code: '1030',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.19080697638409,
      longitude: 16.412616097048836,
    },
  },
  {
    id: '44',
    name: 'Gesundheitszentrum Favoriten Parkplatz',
    street: 'Wienerbergstraße',
    number: '13-19',
    zip_code: '1100',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.16919737702498,
      longitude: 16.345541453462584,
    },
  },
  {
    id: '45',
    name: 'Votivpark Parkgarage',
    street: 'Universitätsstraße',
    number: '',
    zip_code: '1090',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.214165039619225,
      longitude: 16.36037943637143,
    },
  },
  {
    id: '46',
    name: 'P+R Neulaa Parkplatz',
    street: 'Favoritenstraße/Bahnlände',
    number: '',
    zip_code: '1100',
    city: 'Wien',
    contract_templates: [],
    location: {
      latitude: 48.14722955927468,
      longitude: 16.382694002571267,
    },
  },
];

export const exampleOpenParkingSessions: any[] = [
  {
    id: '1',
    garage_name: exampleGarages[0].name,
    price: { amount: '5', currency: 'EUR' },
    duration_in_seconds: 0,
    start_time: '2021-08-01T12:00:00',
    license_plate: {
      license_plate_country: 'D',
      license_plate: 'ARD:1234',
    },
    type: 'ppu',
  },
  {
    id: '2',
    garage_name: exampleGarages[1].name,
    price: { amount: '10', currency: 'USD' },
    duration_in_seconds: 30 * 60,
    start_time: '2021-08-01T13:00:00',
    license_plate: {
      license_plate_country: 'A',
      license_plate: 'AR:5678',
    },
    type: 'ppu',
  },
  {
    id: '3',
    garage_name: exampleGarages[2].name,
    price: { amount: '15.0', currency: 'EUR' },
    duration_in_seconds: 180 * 60,
    start_time: '2021-08-01T14:00:00',
    license_plate: {
      license_plate_country: 'SRB',
      license_plate: 'AR9012',
    },
    type: 'contract',
  },
];

export const exampleUnpaidParkingSessions: any[] = generateExampleUnpaidParkingSessions(100);

export const exampleSignupSearchResponse: any = {
  amount_unpaid: {
    amount: '0',
    currency: 'EUR',
  },
  entry_time: '2020-01-01T00:00:00.000000',
  duration_in_seconds: 3600,
  license_plate: {
    license_plate: 'G:ARIVO1',
    license_plate_country: 'A',
  },
  is_cleared: true,
};

export const exampleBillingInvoices: any[] = generateExampleInvoices(100);

export const examplePaymentMethod: any = generateRandomPaymentMethod();

export const exampleVehicles: any[] = [
  {
    id: '1',
    license_plate_country: 'A',
    license_plate: 'G:ARIVO1',
    enabled: true,
    garage_contracts: [
      {
        id: '1',
        name: 'Testvertrag 1',
        group_limit: 2,
        garage: {
          name: 'Testgarage 1',
        },
      },
      {
        id: '2',
        name: 'Testvertrag 2',
        group_limit: 1,
        garage: {
          name: 'Testgarage 2',
        },
      },
    ],
  },
  {
    id: '2',
    license_plate_country: 'SLO',
    license_plate: 'LJ:577PK',
    enabled: true,
    garage_contracts: [],
  },
  {
    id: '3',
    license_plate_country: 'A',
    license_plate: 'LI:EBE1',
    enabled: true,
    garage_contracts: [
      {
        id: '3',
        name: 'Testvertrag 1',
        group_limit: 2,
        garage: {
          name: 'Testgarage 1',
        },
      },
    ],
  },
  {
    id: '4',
    license_plate_country: 'A',
    license_plate: 'SO:NNE1',
    enabled: false,
    garage_contracts: [],
  },
];

//Factory functions

function generateExampleInvoices(count: number): any[] {
  const invoices: any[] = [];
  // const statuses = ['cleared', 'open', 'uncollectible'];
  const productCategories = ['contract', 'pay_per_use', 'reservation'];
  const currencies = ['EUR'];

  for (let i = 1; i <= count; i++) {
    const amountPerMonth = Math.floor(4 * Math.random());
    for (let j = 0; j < amountPerMonth; j++) {
      const type = Math.random() < 0.9 ? 'invoice' : 'reversal';
      let randomValue = Math.random();
      let status;
      if (randomValue < 0.7) {
        status = 'cleared';
      } else if (randomValue < 0.95) {
        status = 'open';
      } else {
        status = 'uncollectible';
      }
      let product_category = productCategories[Math.floor(Math.random() * productCategories.length)];
      const invoice: any = {
        id: i.toString() + '-' + j.toString(),
        invoice_number: `-${i.toString().padStart(3, '0')}-${j.toString().padStart(2, '0')}`,
        invoice_date: new Date(2022, i - 1, j + 1).toISOString(),
        invoice_type: type,
        product_category: product_category,
        period_start: new Date(2022, i - 1, 1).toISOString(),
        period_end: new Date(2022, i, 1).toISOString(),
        costs: { amount: Math.floor(1000 * Math.random()), currency: currencies[i % currencies.length] },
        fees: null,
        status: status,
        pdf_url: 'https://arivo.co',
      };

      if (type === 'reversal') {
        invoice.invoice_number = 'REV' + invoice.invoice_number;
      } else {
        invoice.invoice_number = 'INV' + invoice.invoice_number;
      }

      if (Math.random() < 0.5) {
        invoice.fees = { amount: Math.floor(10 * Math.random()) + 1, currency: currencies[i % currencies.length] };
      }
      invoice.costs.amount = invoice.costs.amount.toString();
      invoices.push(invoice);
    }
  }

  return invoices;
}

export function generateExampleUnpaidParkingSessions(count: number): any[] {
  const sessions = [];
  const licensePlates = examplePayPerUseLicensePlates;
  const garageNames = exampleGarages.map((garage) => garage.name);
  const currencies = ['EUR'];

  if (licensePlates.length === 0) {
    return [];
  }

  for (let i = 1; i <= count; i++) {
    const date = new Date(2022, 0, 0, 0, 0, 0);
    const entryDate = new Date(2023, 0, i - 1, 10, 0, 0);
    const exitDate = new Date(entryDate);
    exitDate.setHours(entryDate.getHours() + Math.floor(Math.random() * 48) + 1); // Exit time is 1 to 48 hours after entry
    exitDate.setMinutes(Math.floor(Math.random() * 60)); // Exit time is 1 to 24 hours after entry
    date.setFullYear(exitDate.getFullYear());
    date.setMonth(exitDate.getMonth());

    const durationInHours = (exitDate.getTime() - entryDate.getTime()) / (1000 * 60 * 60);
    const priceAmount = (Math.floor(Math.max(1, durationInHours)) * 2.3).toFixed(2);

    const session = {
      id: i.toString(),
      date: date.toISOString(),
      license_plate: licensePlates[Math.floor(Math.random() * licensePlates.length)],
      garage_name: garageNames[Math.floor(Math.random() * garageNames.length)],
      entry_date_time: entryDate.toISOString(),
      exit_date_time: exitDate.toISOString(),
      price: {
        amount: priceAmount.toString(),
        currency: currencies[Math.floor(Math.random() * currencies.length)],
      },
    };
    sessions.push(session);
  }

  return sessions;
}

export function generateRandomPaymentMethod() {
  const cardBrands = ['visa', 'mc', 'amex', 'diners', 'discover', 'jcb', 'default'];
  const paymentTypes: PaymentType[] = ['scheme'];
  const randomCardBrand = cardBrands[Math.floor(Math.random() * cardBrands.length)];
  const randomPaymentType: PaymentType = paymentTypes[Math.floor(Math.random() * paymentTypes.length)];

  return {
    type: randomPaymentType,
    brand: randomPaymentType === 'scheme' ? randomCardBrand : undefined,
    card_summary: randomPaymentType === 'scheme' ? '0000' : undefined,
  };
}

export function addRandomProductsToGarages(garages: any[]): void {
  garages.forEach((garage) => {
    // const numberOfProducts = Math.floor(Math.random() * 3) + 1; // Random number between 1 and 3
    const numberOfProducts = exampleProducts.length; // Hardcode all products for better reproducible testing
    const selectedProducts: any[] = [];
    const availableProducts = [...exampleProducts]; // Create a copy of exampleProducts

    while (selectedProducts.length < numberOfProducts && availableProducts.length > 0) {
      const randomIndex = Math.floor(Math.random() * availableProducts.length);
      const randomProduct = JSON.parse(JSON.stringify(availableProducts.splice(randomIndex, 1)[0])); // Remove the product from availableProducts and create a deep copy
      randomProduct.id = `${garage.id}-${randomProduct.id}`; // Add garage ID as prefix
      randomProduct.initial_start = '2024-10-10T00:00:00';
      selectedProducts.push(randomProduct);
    }

    garage.contract_templates = selectedProducts;
  });
}

export function generateRandomOpenParkingSessions(count: number): any[] {
  if (exampleLicensePlates.length === 0) {
    return [];
  }

  const sessions = [];
  const currency = 'EUR';
  const types = ['ppu', 'contract'];

  for (let i = 0; i < count; i++) {
    const randomGarage = exampleGarages[Math.floor(Math.random() * exampleGarages.length)];
    const randomLicensePlate = exampleLicensePlates[Math.floor(Math.random() * exampleLicensePlates.length)];
    const randomType = types[Math.floor(Math.random() * types.length)];
    const randomStartTime = new Date(Date.now() - Math.floor(Math.random() * 7 * 24 * 60 * 60 * 1000)).toISOString(); // Random start time within the last 30 days
    const durationInSeconds = Math.floor((Date.now() - new Date(randomStartTime).getTime()) / 1000); // Duration from start time until now
    const durationInHours = durationInSeconds / 3600;
    const priceAmount = parseFloat((durationInHours * 1.1).toFixed(2)); // Price based on duration

    const session = {
      id: (i + 1).toString(),
      garage_name: randomGarage.name,
      price: { amount: priceAmount.toString(), currency: currency },
      duration_in_seconds: durationInSeconds,
      start_time: randomStartTime,
      license_plate: {
        license_plate_country: randomLicensePlate.license_plate_country,
        license_plate: randomLicensePlate.license_plate,
      },
      type: randomType,
    };

    sessions.push(session);
  }

  return sessions;
}
