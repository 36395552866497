import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Vehicle } from '../../shared/models/licensePlate';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from '../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class VehiclesApiService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  getVehiclesResponse(): Observable<Vehicle[]> {
    return this.http.get<Vehicle[]>(`/api/backoffice/v2/customer/license_plate/`).pipe(
      catchError((error) => {
        this.router.navigateByUrl('/dashboard');
        this.toastService.add({ type: 'error', message: this.translateService.instant('Common.Errors.PageCouldNotBeLoaded') });
        return of();
      }),
    );
  }

  deleteVehicle(vehicleId: string): Observable<null> {
    return this.http.delete<null>(`/api/backoffice/v2/customer/license_plate/${vehicleId}/`);
  }
}
