import { Injectable } from '@angular/core';
import { NavigationEnd, Router, UrlSegment } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.urlAfterRedirects;
        const previousUrl = this._currentUrl;

        if (this.getPathWithoutParams(currentUrl) !== this.getPathWithoutParams(previousUrl!)) {
          this._previousUrl = this._currentUrl;
        }
        this._currentUrl = currentUrl;
      }
    });
  }

  private getPathWithoutParams(url: string): string {
    const urlTree = this.router.parseUrl(url);
    return urlTree.root.children['primary']?.segments.map((segment: UrlSegment) => segment.path).join('/') || '';
  }

  get previousUrl(): string | null {
    return this._previousUrl;
  }

  private _previousUrl: string | null = null;
  private _currentUrl: string | null = null;
}
