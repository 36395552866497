import { Component, Input, OnChanges } from '@angular/core';
import { checkLicensePlateFormat, getCountryByLPRCode } from '../../../utils/license-plate.utils';
import { LicensePlateCountry, LPRCountryCode } from '../../../models/country';

const friendshipArray: { country: LPRCountryCode; plate: string }[] = [
  { country: 'A', plate: 'G499VG' },
  { country: 'A', plate: 'G892CW' },
  { country: 'A', plate: 'JIGGLYPUFF' },
];

@Component({
  selector: 'arivo-license-plate',
  templateUrl: './arivo-license-plate.component.html',
  styleUrl: './arivo-license-plate.component.scss',
})
export class ArivoLicensePlateComponent implements OnChanges {
  @Input() country?: LPRCountryCode;
  @Input() licensePlate?: string;
  @Input() compact: boolean = false;
  @Input() checkInvalid: boolean = false;
  invalidWarning: boolean = false;

  ngOnChanges(): void {
    if (this.country && this.licensePlate && this.checkInvalid) {
      this.invalidWarning = !checkLicensePlateFormat(this.licensePlate, this.country);
    }

    if (this.licensePlate) {
      this.licensePlate = this.licensePlate.split(':').join(' ');
    }
  }

  get fullCountry(): LicensePlateCountry {
    return this.country ? getCountryByLPRCode(this.country) : getCountryByLPRCode('UNKNOWN');
  }

  get customPlate() {
    if (!this.licensePlate) {
      return false;
    }
    try {
      return friendshipArray.some(
        (friendshipPlate) =>
          friendshipPlate.country === this.country && friendshipPlate.plate === this.licensePlate!.split(' ').join('').toUpperCase(),
      );
    } catch (e) {
      return false;
    }
  }
}
