<div class="flex h-full w-full justify-center items-center">
  <div class="align-middle flex flex-col justify-center items-center rounded-2xl p-10">
    <i class="text-8xl fa-light fa-car-burst text-decoration-dark"></i>
    <div class="headline-large mt-4" [innerText]="'Error.OhNo' | translate"></div>
    <div class="body-large mt-2" [innerText]="'Error.SomethingWentWrong' | translate"></div>
    <div
      class="button-primary-outlined mt-4 cursor-pointer"
      [routerLink]="['/dashboard']"
      [innerText]="'Error.DriveMeHome' | translate"
    ></div>
  </div>
</div>
