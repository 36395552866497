import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { ArivoLicensePlateComponent } from './components/ui-components/arivo-license-plate/arivo-license-plate.component';
import { ArivoLicensePlateInputComponent } from './components/ui-components/arivo-license-plate-input/arivo-license-plate-input.component';
import { ArivoStepperComponent } from './components/ui-components/arivo-stepper/arivo-stepper.component';
import {
  ArivoStepComponent,
  ArivoStepperNextDirective,
  ArivoStepperPreviousDirective,
} from './components/ui-components/arivo-stepper/arivo-step/arivo-step.component';
import { ArivoStepFooterComponent } from './components/ui-components/arivo-stepper/arivo-step/arivo-step-footer/arivo-step-footer.component';
import { ArivoAccordionBodyComponent } from './components/ui-components/arivo-accordion/arivo-accordion-body/arivo-accordion-body.component';
import { ArivoAccordionHeaderComponent } from './components/ui-components/arivo-accordion/arivo-accordion-header/arivo-accordion-header.component';
import { ArivoAccordionComponent } from './components/ui-components/arivo-accordion/arivo-accordion.component';
import { FormErrorDirective, FormErrorTextDirective } from './directives/form-error.directive';
import { FormGroupDirective } from './directives/form-group.directive';
import { ArivoLicensePlateInputDirective } from './components/ui-components/arivo-license-plate-input/arivo-license-plate-input.directive';
import { ArivoDialogComponent } from './components/ui-components/arivo-dialog/arivo-dialog.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, provideNativeDateAdapter } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ArivoBottomSheetDialogComponent } from './components/ui-components/arivo-bottom-sheet-dialog/arivo-bottom-sheet-dialog.component';
import { ArivoToastComponent } from './components/arivo-toast/arivo-toast.component';
import { DateFnsPipe } from './pipes/date-fns.pipe';
import { ArivoAlertsComponent } from './components/ui-components/arivo-alerts/arivo-alerts.component';
import { ArivoTabsComponent } from './components/ui-components/arivo-tabs/arivo-tabs.component';
import { ArivoTabComponent } from './components/ui-components/arivo-tabs/arivo-tab/arivo-tab.component';
import { DurationPipe } from './pipes/duration.pipe';
import { DateDiffPipe } from './pipes/date-diff.pipe';
import { LoggedInLayoutComponent } from './components/layouts/logged-in-layout/logged-in-layout.component';
import { PublicLayoutComponent } from './components/layouts/public-layout/public-layout.component';
import { HeaderComponent } from './components/navigation/header/header.component';
import { SidebarComponent } from './components/navigation/sidebar/sidebar.component';
import { ArivoDialogContainerComponent } from './components/arivo-dialog-container/arivo-dialog-container.component';
import { AddEmptyPlaceholderDirective } from './directives/add-empty-placeholder.directive';
import { ArivoPaymentContainerComponent } from './components/arivo-payment-container/arivo-payment-container.component';
import { ArivoPaginatorComponent } from './components/arivo-paginator/arivo-paginator.component';
import { ArivoDatepickerAdapter } from './utils/arivo-datepicker-adapter';
import { ArivoMarketingConsentComponent } from './components/arivo-marketing-consent/arivo-marketing-consent.component';

@NgModule({
  declarations: [
    ArivoLicensePlateComponent,
    ArivoLicensePlateInputComponent,
    ArivoStepperComponent,
    ArivoStepComponent,
    ArivoStepFooterComponent,
    ArivoStepperPreviousDirective,
    ArivoStepperNextDirective,
    ArivoAccordionComponent,
    ArivoAccordionHeaderComponent,
    ArivoAccordionBodyComponent,
    FormErrorDirective,
    FormErrorTextDirective,
    FormGroupDirective,
    ArivoLicensePlateInputDirective,
    ArivoDialogComponent,
    ArivoBottomSheetDialogComponent,
    ArivoToastComponent,
    DateFnsPipe,
    ArivoAlertsComponent,
    ArivoTabsComponent,
    ArivoTabComponent,
    DurationPipe,
    DateDiffPipe,
    LoggedInLayoutComponent,
    PublicLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    ArivoDialogContainerComponent,
    AddEmptyPlaceholderDirective,
    ArivoPaymentContainerComponent,
    ArivoPaginatorComponent,
    ArivoMarketingConsentComponent,
  ],
  imports: [CoreModule, MatTooltipModule, MatDatepickerModule, MatProgressSpinnerModule, MatIconModule],
  exports: [
    ArivoLicensePlateComponent,
    ArivoLicensePlateInputComponent,
    ArivoStepperComponent,
    ArivoStepComponent,
    ArivoStepFooterComponent,
    ArivoStepperPreviousDirective,
    ArivoStepperNextDirective,
    ArivoAccordionComponent,
    ArivoAccordionHeaderComponent,
    ArivoAccordionBodyComponent,
    FormErrorDirective,
    FormErrorTextDirective,
    FormGroupDirective,
    ArivoLicensePlateInputDirective,
    ArivoDialogComponent,
    MatInputModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    ArivoBottomSheetDialogComponent,
    ArivoToastComponent,
    MatIconModule,
    DateFnsPipe,
    ArivoAlertsComponent,
    ArivoTabsComponent,
    ArivoTabComponent,
    DurationPipe,
    DateDiffPipe,
    LoggedInLayoutComponent,
    PublicLayoutComponent,
    AddEmptyPlaceholderDirective,
    ArivoPaymentContainerComponent,
    ArivoPaginatorComponent,
    ArivoMarketingConsentComponent,
  ],
  providers: [provideNativeDateAdapter(), { provide: DateAdapter, useClass: ArivoDatepickerAdapter }],
})
export class SharedModule {}
