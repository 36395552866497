<form [formGroup]="licensePlateForm">
  <div class="flex gap-2" [ngClass]="inline ? 'license-plate-inline' : 'flex-col'">
    <div class="input-primary-container" [ngClass]="{ 'flex-1': inline }">
      <select class="input-primary" formControlName="licensePlateCountry">
        @for (country of countries; track country.iso2Code) {
          <option
            [value]="country.lprCode"
            [innerText]="country.lprCode + ' - ' + ('CountryNames.' + country.name | translate)"
            *ngIf="country.lprCode !== 'UNKNOWN'"
          ></option>
        }
        <option value="UNKNOWN" [innerText]="'Common.Other' | translate"></option>
      </select>
      <label class="label-primary" [innerText]="'Common.CountryOfRegistration' | translate"></label>
    </div>
    <div class="flex flex-col gap-2" [ngClass]="{ 'flex-1': inline }">
      <div class="license-plate-input" [ngClass]="{ 'license-plate-input-eu': selectedCountry.euCountry, 'flex-grow': inline }">
        <div class="license-plate-country" *ngIf="selectedCountry.lprCode !== 'UNKNOWN'">
          <div class="license-plate-country-flag">
            <span [ngClass]="selectedCountry.euCountry ? 'fi fi-eu' : 'fi fi-' + selectedCountry.iso2Code.toLowerCase()"></span>
          </div>
          <div class="license-plate-country-name" [innerText]="licensePlateForm.controls['licensePlateCountry'].value"></div>
        </div>
        @if (selectedCountry.firstPartLength) {
          <div class="input-primary-container license-plate-input-first">
            <input
              [placeholder]="licensePlateFirstInputPlaceholder"
              [maxlength]="selectedCountry.firstPartLength"
              (input)="jumpToNextInput($event)"
              formControlName="licensePlateFirstPart"
              #licensePlateFirstInput
              licensePlateDirective
              (blur)="markAsTouched('licensePlateFirstPart')"
            />
          </div>
          <div class="input-primary-container license-plate-input-second">
            <input
              [placeholder]="licensePlateSecondInputPlaceholder"
              (keydown)="jumpToPreviousInput($event)"
              formControlName="licensePlateSecondPart"
              #licensePlateSecondInput
              licensePlateDirective
              (blur)="markAsTouched('licensePlateSecondPart')"
            />
          </div>
        } @else {
          <div class="input-primary-container flex-grow">
            <input
              [placeholder]="licensePlateInputPlaceholder"
              formControlName="licensePlate"
              #licensePlateInput
              licensePlateDirective
              (blur)="markAsTouched('licensePlate')"
            />
          </div>
        }
      </div>
      @if (showErrors) {
        @if (selectedCountry.firstPartLength) {
          <p
            class="error"
            *ngIf="
              licensePlateForm.controls['licensePlateSecondPart'].touched &&
              licensePlateForm.controls['licensePlate'].value !== '' &&
              licensePlateForm.errors?.['invalidFormat']
            "
            [innerHtml]="'UiComponents.LicensePlateInput.LicensePlateMaybeInvalid' | translate"
          ></p>
        } @else {
          <p
            class="error"
            *ngIf="
              licensePlateForm.controls['licensePlate'].touched &&
              licensePlateForm.controls['licensePlate'].value !== '' &&
              licensePlateForm.errors?.['invalidFormat']
            "
            [innerHtml]="'UiComponents.LicensePlateInput.LicensePlateMaybeInvalid' | translate"
          ></p>
        }
      }
    </div>
  </div>
</form>
