// src/app/shared/components/arivo-dialog-container/dialog-config.ts

import { TranslateService } from '@ngx-translate/core';
import { Dialog } from '../../services/dialog.service';
import { environment } from '../../../../environments/environment';

export function createDialogConfig(translateService: TranslateService) {
  return {
    getPaymentMethodReminderDialog(): Dialog {
      let content =
        translateService.instant('Dialog.PaymentMethodMissing.Content') + ' ' + translateService.instant('Dialog.GenericBanWarning');
      if (environment.featureFlags.enableContractCreation) {
        content += '<br/>' + translateService.instant('Dialog.NoContractsWithoutPaymentMethodWarning');
      }

      return {
        title: translateService.instant('Dialog.PaymentMethodMissing.Title'),
        subtitle: translateService.instant('Dialog.PaymentMethodMissing.Subtitle'),
        content,
        dismissible: true,
        buttonText: translateService.instant('Dialog.PaymentMethodMissing.ButtonText'),
        buttonRoute: { link: '/billing', queryParams: { showPaymentForm: 'true' } },
        type: 'error',
      };
    },

    getCustomerAddressReminderDialog(): Dialog {
      let content =
        translateService.instant('Dialog.CustomerAddressMissing.Content') + ' ' + translateService.instant('Dialog.GenericBanWarning');
      if (environment.featureFlags.enableContractCreation) {
        content += '<br/>' + translateService.instant('Dialog.NoContractsWithoutPaymentMethodWarning');
      }

      return {
        title: translateService.instant('Dialog.CustomerAddressMissing.Title'),
        subtitle: translateService.instant('Dialog.CustomerAddressMissing.Subtitle'),
        content,
        dismissible: true,
        buttonText: translateService.instant('Dialog.CustomerAddressMissing.ButtonText'),
        buttonRoute: { link: '/data' },
        type: 'error',
      };
    },

    getFinishRegistrationReminderDialog(): Dialog {
      let content =
        translateService.instant('Dialog.PaymentMethodAndCustomerAddressMissing.Content') +
        ' ' +
        translateService.instant('Dialog.GenericBanWarning');
      if (environment.featureFlags.enableContractCreation) {
        content += '<br/>' + translateService.instant('Dialog.NoContractsWithoutPaymentMethodWarning');
      }

      return {
        title: translateService.instant('Dialog.PaymentMethodAndCustomerAddressMissing.Title'),
        subtitle: translateService.instant('Dialog.PaymentMethodAndCustomerAddressMissing.Subtitle'),
        content,
        dismissible: true,
        buttonText: translateService.instant('Dialog.PaymentMethodAndCustomerAddressMissing.ButtonText'),
        buttonRoute: { link: '/onboarding/finish' },
        type: 'error',
      };
    },

    getPaymentFailedDialog(): Dialog {
      return {
        title: translateService.instant('Dialog.PaymentFailed.Title'),
        subtitle: translateService.instant('Dialog.PaymentFailed.Subtitle'),
        content: translateService.instant('Dialog.PaymentFailed.Content'),
        buttonText: translateService.instant('Dialog.PaymentFailed.ButtonText'),
        buttonRoute: { link: '/billing', queryParams: { showPaymentForm: 'true' } },
        type: 'error',
      };
    },

    getBanImminentDialog(): Dialog {
      return {
        title: translateService.instant('Dialog.BanImminent.Title'),
        subtitle: translateService.instant('Dialog.BanImminent.Subtitle'),
        content: translateService.instant('Dialog.BanImminent.Content'),
        buttonText: translateService.instant('Dialog.BanImminent.ButtonText'),
        buttonRoute: { link: '/billing', queryParams: { showPaymentForm: 'true' } },
        type: 'error',
      };
    },

    getBannedDialog(): Dialog {
      return {
        title: translateService.instant('Dialog.Banned.Title'),
        content: translateService.instant('Dialog.Banned.Content'),
        buttonText: translateService.instant('Dialog.Banned.ButtonText'),
        type: 'error',
      };
    },

    getMissingPaymentMethodBannedDialog(): Dialog {
      return {
        title: translateService.instant('Dialog.MissingPaymentMethod.Title'),
        subtitle: translateService.instant('Dialog.MissingPaymentMethod.Subtitle'),
        content: translateService.instant('Dialog.MissingPaymentMethod.Content'),
        buttonText: translateService.instant('Dialog.MissingPaymentMethod.ButtonText'),
        buttonRoute: { link: '/billing', queryParams: { showPaymentForm: 'true' } },
        type: 'error',
      };
    },

    getWiparkWelcomeDialog(): Dialog {
      return {
        title: translateService.instant('Dialog.WelcomeWipark.Title'),
        content: translateService.instant('Dialog.WelcomeWipark.Content'),
        dismissible: true,
        dismissButtonText: translateService.instant('Dialog.WelcomeWipark.Dismiss'),
        type: 'info',
      };
    },

    getWiparkAccountCompletionDialog(): Dialog {
      return {
        title: translateService.instant('Dialog.WelcomeWiparkFinished.Title'),
        content: translateService.instant('Dialog.WelcomeWiparkFinished.Content'),
        dismissible: true,
        dismissButtonText: translateService.instant('Dialog.WelcomeWiparkFinished.Dismiss'),
        type: 'info',
      };
    },
  };
}
