<div class="toast-container">
  @for (toast of toasts; track $index) {
    <div class="toast" [ngClass]="toast.type" [@toastAnimation] (click)="removeToast($index)">
      <div class="icon-container">
        @switch (toast.type) {
          @case ('info') {
            <i class="icon fa-kit fa-info"></i>
          }
          @case ('success') {
            <i class="icon fa-light fa-circle-check"></i>
          }
          @case ('warning') {
            <i class="icon fa-light fa-triangle-exclamation"></i>
          }
          @case ('error') {
            <i class="icon fa-light fa-triangle-exclamation"></i>
          }
        }
      </div>
      <div class="message-container" [ngClass]="{ 'flex items-center': !toast.title }">
        @if (toast.title) {
          <h3 class="font-bold" [innerText]="toast.title"></h3>
        }
        <p class="body-medium" [innerText]="toast.message"></p>
      </div>
    </div>
  }
</div>
